import React, { useEffect, useState } from "react";
import PostsPerDayChart from "../components/PostsPerDayChart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pie, Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

// Register all necessary components for Pie, Bar, and Line charts
Chart.register(...registerables);

const Analytics = ({ domain, userData }) => {
  const navigate = useNavigate();
  const [postsPerDay, setPostsPerDay] = useState([]);
  const [usersPerDay, setUsersPerDay] = useState([]);
  const [postsByCategory, setPostsByCategory] = useState([]);
  const [postsByCity, setPostsByCity] = useState([]);

  const fetchPostsPerDay = () => {
    fetch("/api/posts/postsperday")
      .then((response) => response.json())
      .then((data) => setPostsPerDay(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const fetchUsersPerDay = () => {
    fetch("/api/posts/usersperday")
      .then((response) => response.json())
      .then((data) => setUsersPerDay(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const fetchPostsByCategory = () => {
    fetch("/api/posts/postsbycategory")
      .then((response) => response.json())
      .then((data) => setPostsByCategory(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const fetchPostsByCity = () => {
    fetch("/api/posts/postsbycity")
      .then((response) => response.json())
      .then((data) => {
        // Remove "Total" from chart data
        const filteredData = data.filter((item) => item.city !== "Total");
        setPostsByCity(filteredData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    fetchPostsPerDay();
    fetchUsersPerDay();
    fetchPostsByCategory();
    fetchPostsByCity();
  }, []);

  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")}`;
  };

  // Filter out the "Total" category from the dataset
  const filteredCategories = postsByCategory.filter(
    (item) => item.category !== "Total"
  );

  const backgroundColors = filteredCategories.map(() => getRandomColor());

  const chartData = {
    labels: filteredCategories.map((item) => item.category),
    datasets: [
      {
        data: filteredCategories.map((item) => item.totalCount),
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
      },
    ],
  };

  // Find the total count separately
  const totalCount =
    postsByCategory.find((item) => item.category === "Total")?.totalCount || 0;

  const cityChartData = {
    labels: postsByCity.map((item) => item.city || "Unknown"),
    datasets: [
      {
        label: "Total Posts",
        data: postsByCity.map((item) => item.totalCount),
        backgroundColor: postsByCity.map(() => getRandomColor()),
        borderWidth: 1,
      },
    ],
  };

  const cityChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false }, // Hide legend (optional)
      tooltip: { enabled: true },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: "Number of Posts" },
      },
      x: {
        title: { display: true, text: "Cities" },
      },
    },
  };

  const postsPerDayChartData = {
    labels: postsPerDay.map((item) => item.date), // X-axis: Dates
    datasets: [
      {
        label: "Bleeps Per Day",
        data: postsPerDay.map((item) => item.count), // Y-axis: Total posts
        fill: false,
        borderColor: "#ff7300",
        backgroundColor: "#ff7300",
        tension: 0.3, // Smooth curve
        pointRadius: 5, // Dots on the line
        pointBackgroundColor: "#ff7300",
      },
    ],
  };

  const postsPerDayChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        title: { display: true, text: "Date" },
        ticks: { autoSkip: true, maxTicksLimit: 7 }, // Limits labels for readability
      },
      y: {
        beginAtZero: true,
        title: { display: true, text: "Number of Posts" },
      },
    },
  };

  const usersPerDayChartData = {
    labels: usersPerDay.map((item) => item.date), // X-axis: Dates
    datasets: [
      {
        label: "New Users Per Day",
        data: usersPerDay.map((item) => item.count), // Y-axis: Total users
        fill: false,
        borderColor: "#FF6384", // Change to any desired color
        backgroundColor: "#FF6384", // Same color for points
        tension: 0.3, // Smooth curve
        pointRadius: 5, // Dots on the line
        pointBackgroundColor: "#FF6384", // Same color for points
      },
    ],
  };

  const usersPerDayChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        title: { display: true, text: "Date" },
        ticks: { autoSkip: true, maxTicksLimit: 7 }, // Limits labels for readability
      },
      y: {
        beginAtZero: true,
        title: { display: true, text: "Number of Users" },
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Analytics | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <div
          className="row d-flex align-items-center"
          style={{
            position: "relative",
          }}
        >
          <div
            className="col"
            style={{ color: "white", padding: "20px" }}
          >
            <div
              className="col d-flex align-items-center"
              style={{ color: "white", flexDirection: "row" }}
            >
              <Link
                to="/"
                className="text-decoration-none text-white"
              >
                <span
                  style={{
                    backgroundColor: "#00000089",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginRight: "10px",
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </span>
              </Link>

              <div>
                <b
                  style={{
                    fontSize: "30px",
                    textShadow: "#1c1c1c 1px 2px 0px",
                  }}
                >
                  Analytics
                </b>
              </div>
            </div>
            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              Visualize Bleep's data!
            </p>
          </div>
        </div>
      </div>

      {/* <PostsPerDayChart data={postsPerDay} /> */}

      <div
        style={{
          position: "relative",
          paddingBlock: "50px",
          paddingInline: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            margin: "auto",
            padding: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3 style={{ color: "black", textAlign: "center" }}>Bleep Trends</h3>
          <div style={{ height: "400px" }}>
            <Line
              data={postsPerDayChartData}
              options={postsPerDayChartOptions}
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            margin: "auto",
            padding: "20px",
            marginTop: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3 style={{ color: "black", textAlign: "center" }}>
            User Acquisition Trends
          </h3>
          <div style={{ height: "400px" }}>
            <Line
              data={usersPerDayChartData}
              options={usersPerDayChartOptions}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap", // Allows items to wrap on smaller screens
            justifyContent: "center", // Centers content horizontally
            alignItems: "center", // Aligns items vertically
            gap: "20px", // Adds space between the charts
            padding: "20px",
          }}
        >
          {/* Pie Chart Section */}
          <div
            style={{
              flex: "1",
              minWidth: "350px", // Prevents the chart from being too small
              maxWidth: "500px", // Prevents the chart from being too wide
              backgroundColor: "#ffffff",
              minHeight: "500px",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
              textAlign: "center",
            }}
          >
            <h3 style={{ color: "black" }}>Bleeps by Category</h3>
            <Pie data={chartData} />
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginTop: "15px",
                color: "black",
              }}
            >
              Total Bleeps: {totalCount}
            </p>
          </div>

          {/* Bar Chart Section */}
          <div
            style={{
              flex: "1",
              minWidth: "350px",
              maxWidth: "600px",
              minHeight: "600px",
              backgroundColor: "#ffffff",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Vertically center the content
              alignItems: "center", // Horizontally center the content
            }}
          >
            <h3 style={{ color: "black", textAlign: "center" }}>
              Bleeps by City
            </h3>
            <div style={{ height: "400px", width: "100%" }}>
              <Bar
                data={cityChartData}
                options={cityChartOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
