import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";

const CreateGenuineProduct = ({
  domain,
  show,
  handleClose,
  handleFormSubmit,
}) => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Retrieve user permissions from cookies
    setUserId(Cookies.get("userId"));
  }, []);

  const [selectedImages, setSelectedImages] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    // Prevent selecting more than 8 images
    if (files.length + selectedImages.length > 8) {
      alert("You can only upload up to 8 images.");
      return;
    }

    setSelectedImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const createProduct = () => {
    const brand = document.getElementById("brand").value;
    const category = document.getElementById("category").value;
    const description = document.getElementById("description").value;
    const address = document.getElementById("address").value;
    const price = document.getElementById("price").value;
    const phone = document.getElementById("phone").value;
    const whatsapp = document.getElementById("whatsapp").value;
    const email = document.getElementById("email").value;
    const website = document.getElementById("website").value;
    const postimages = selectedImages; // assuming selectedImages is an array of File objects

    if (!userId) {
      console.error("User not logged in");
      return;
    }

    const formdata = new FormData();
    formdata.append("user", userId);
    formdata.append("brand", brand);
    formdata.append("category", category);
    formdata.append("description", description);
    formdata.append("address", address);
    formdata.append("price", price);
    formdata.append("phone", phone);
    formdata.append("whatsapp", whatsapp);
    formdata.append("email", email);
    formdata.append("website", website);
    formdata.append("country", "Uganda");

    // Loop through selected images and append them to FormData
    postimages.forEach((image, index) => {
      formdata.append("postimages", image, image.name); // Use image.name as the filename
    });

    fetch("/api/genuineProducts", {
      method: "POST",
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((data) => {
        handleClose();
        handleFormSubmit();
        setSelectedImages([]);
      })
      .catch((error) => console.error(error));
  };

  const [currency, setCurrency] = useState("UGX");
  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Add genuine product</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="brand"
            name="brand"
            placeholder="Brand"
          />
          <label htmlFor="brand">Brand Name*</label>
        </div>

        <div className="mb-2">
          <label
            htmlFor="images"
            className="form-label"
          >
            <b>Upload Product Images (Max 8)</b>
          </label>
          <div
            style={{
              border: "2px dashed #ccc",
              padding: "10px",
              borderRadius: "8px",
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#f9f9f9",
            }}
            onClick={() => document.getElementById("images").click()}
          >
            <span>
              {selectedImages.length === 0 ? (
                <>
                  <i className="fas fa-plus-circle"></i> Select images
                </>
              ) : (
                `${selectedImages.length} image(s) selected`
              )}
            </span>
          </div>

          {/* Hidden file input */}
          <input
            type="file"
            className="form-control"
            id="images"
            name="images"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            disabled={selectedImages.length >= 8} // Prevents adding more if at the limit
          />
        </div>

        {/* Image Previews */}
        {selectedImages.length > 0 && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              paddingBlock: "10px",
              overflowX: "auto", // Enables horizontal scrolling if needed
              whiteSpace: "nowrap",
            }}
          >
            {selectedImages.map((image, index) => (
              <div
                key={index}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "8px",
                  position: "relative",
                  flexShrink: 0, // Prevents image from shrinking in scrollable container
                }}
              >
                <img
                  src={URL.createObjectURL(image)}
                  alt={image.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                />
                {/* "X" Button to Remove Image */}
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  style={{
                    position: "absolute",
                    top: -5,
                    right: -5,
                    backgroundColor: "rgba(255, 0, 0, 0.7)",
                    color: "white",
                    border: "none",
                    width: "18px",
                    height: "18px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    fontSize: "12px",
                    lineHeight: "12px",
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="category"
            name="category"
            placeholder="Category"
          />
          <label htmlFor="category">Category</label>
        </div>

        <div className="mb-2">
          <textarea
            className="form-control"
            placeholder="Product Description"
            id="description"
            name="description"
            style={{ height: "100px" }}
          ></textarea>
        </div>

        <div className="mb-2">
          <textarea
            className="form-control"
            placeholder="Address"
            id="address"
            name="address"
            style={{ height: "100px" }}
          ></textarea>
        </div>

        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control"
            id="price"
            name="price"
            placeholder="Price"
          />
          <span className="input-group-text">{currency}</span>
        </div>

        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="phone"
            name="phone"
            placeholder="Phone"
          />
          <label htmlFor="phone">Phone number</label>
        </div>

        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="whatsapp"
            name="whatsapp"
            placeholder="Whatsapp"
          />
          <label htmlFor="whatsapp">Whatsapp number</label>
        </div>

        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="email"
            name="email"
            placeholder="Email"
          />
          <label htmlFor="email">Email</label>
        </div>

        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="website"
            name="website"
            placeholder="Website"
          />
          <label htmlFor="website">Website</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => createProduct()}
          className="btn btn-danger btn-lg"
        >
          Submit <i className="fas fa-arrow-right"></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateGenuineProduct;
