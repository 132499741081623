import React, { useEffect, useState } from "react";
import Post from "../components/Post";
import { Link } from "react-router-dom";
import lottie from "lottie-web";
// import Cookies from "js-cookie";
import BleepSpinnerDark from "../components/lottie/BleepSpinnerDark.json";
import InfiniteScroll from "react-infinite-scroll-component";

import { Helmet } from "react-helmet-async";

const Bleeps = ({ domain, userData }) => {
  const [userDataState, setUserDataState] = useState(userData);
  const [searchTerm, setSearchTerm] = useState("");
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  // Get the search term from the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchTerm = urlParams.get("q");
    if (searchTerm) {
      setSearchTerm(searchTerm);
    }
  }, []);

  const bleeploadinganimation = React.useRef(null);
  useEffect(() => {
    if (
      bleeploadinganimation.current &&
      !bleeploadinganimation.current.animationLoaded
    ) {
      bleeploadinganimation.current.animationLoaded = true; // Mark animation as loaded to prevent re-render
      lottie.loadAnimation({
        container: bleeploadinganimation.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        animationData: BleepSpinnerDark,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    setUserDataState(userData);
  }, [userData]);

  const [
    userId,
    userType,
    userName,
    userEmail,
    userPassword,
    userOrg,
    userPermissions,
    userAnonymous,
  ] = userDataState;

  const handleShowSignIn = () => {
    window.location.href = "/signin?redirect=" + window.location.pathname;
  };

  const fetchPosts = () => {
    let url = "";
    url = `/api/posts/search?searchterm=${searchTerm}&page=${page}&size=4`;

    if (searchTerm) {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setPosts((prevPosts) => [...prevPosts, ...data]);
          data.length > 0 ? setHasMore(true) : setHasMore(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching posts:", error);
          setIsLoading(false);
        });
      setPage(page + 1);
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    fetchPosts();
  }, [userId, searchTerm]);

  return (
    <>
      <Helmet>
        <title>BLEEP - Suspect a counterfeit? Report Now!</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div className="row m-0">
        <div
          className="col"
          style={{
            paddingTop: "10px",
            paddingInline: "0px",
            background:
              "linear-gradient(0deg, #150102 95%, rgba(255, 255, 255, 0)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              paddingInline: 20,
              paddingTop: 20,
            }}
          >
            {searchTerm && (
              <span
                style={{
                  color: "white",
                  fontSize: "1.5em",
                  textAlign: "center",
                  display: "flex",
                  flexWrap: "wrap", // Ensures proper wrapping on smaller screens
                  padding: "10px",
                  maxWidth: "100%",
                }}
              >
                Showing Bleeps matching&nbsp;<b>"{searchTerm}"</b>
              </span>
            )}
          </div>
          <div
            style={{
              overflow: "hidden",
              minHeight: "50vh",
              paddingBottom: 20,
            }}
          >
            <InfiniteScroll
              dataLength={posts.length}
              next={fetchPosts}
              hasMore={hasMore}
              style={{ overflow: "unset" }}
            >
              <div
                className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-1 row-cols-1"
                style={{
                  margin: 0,
                  padding: 0,
                  paddingTop: "10px",
                }}
              >
                {posts &&
                  posts.map((post) => (
                    <Post
                      key={post._id}
                      post={post}
                      showSignIn={handleShowSignIn}
                    />
                  ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bleeps;
