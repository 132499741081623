import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// import { UG, KE } from "country-flag-icons/react/3x2";
import lottie from "lottie-web";
import BleepAnimationDark from "../components/lottie/BleepAnimationDark.json";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";

const Navbar = ({ domain, userData, signOut, search }) => {
  const userId = userData[0];
  const userType = userData[1];
  const userName = userData[2];
  const userEmail = userData[3];
  const userPassword = userData[4];
  const userOrg = userData[5];
  const userPermissions = userData[6];
  const userAnonymous = userData[7];
  const [displayName, setDisplayName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const searchInputRef = useRef(null);

  // update search query
  useEffect(() => {
    setSearchQuery(search);
  }, [search]);

  // Focus search input when '/' key is pressed
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.key === "/" &&
        document.activeElement !== searchInputRef.current
      ) {
        event.preventDefault(); // Prevent typing '/' in input
        searchInputRef.current.focus();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery) {
      window.location.href = `/bleeps?q=${searchQuery}`;
    }
  };

  useEffect(() => {
    if (userAnonymous === "true" && userId) {
      setDisplayName(`Bleeper#${userId.slice(-6)}`);
    } else {
      setDisplayName(userName);
    }
  }, [userId, userAnonymous, userName]);

  const avatar = React.useMemo(() => {
    return createAvatar(identicon, {
      seed: displayName,
      backgroundType: ["gradientLinear", "solid"],
    }).toDataUri();
  }, [displayName]);

  const [isHovered, setIsHovered] = useState(false);
  const bleepanimation = React.useRef(null);
  useEffect(() => {
    if (bleepanimation.current && !bleepanimation.current.animationLoaded) {
      bleepanimation.current.animationLoaded = true; // Mark animation as loaded to prevent re-render
      lottie.loadAnimation({
        container: bleepanimation.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        animationData: BleepAnimationDark,
      });
    }
  }, []);

  return (
    <header className="sticky-top">
      <div className="bleep-navbar">
        <div
          className="d-flex align-items-center"
          style={{ paddingBlock: "8px" }}
        >
          <div className="row w-100">
            <div
              className="col-2 justify-content-start align-items-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "40px",
              }}
            >
              <Link
                to="/"
                className="text-decoration-none d-flex align-items-center"
              >
                <div
                  style={{ position: "relative", display: "inline-block" }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {/* Image */}
                  <img
                    src="/images/bleeplogo_primary.png"
                    // className={`hover-image ${
                    //   isHovered ? "fade-out" : "fade-in"
                    // }`}
                    style={{
                      width: "140px",
                      transition: "opacity 0.5s ease", // Smooth transition effect
                      // opacity: isHovered ? 0 : 1, // Fade out on hover
                    }}
                  />

                  {/* Animated div */}
                  {/* <div
                    ref={bleepanimation}
                    className={`hover-div ${
                      isHovered ? "fade-in" : "fade-out"
                    }`}
                    style={{
                      position: "absolute",
                      top: -45,
                      width: 150,
                      height: 150,
                      transition: "opacity 0.5s ease", // Smooth transition effect
                      opacity: isHovered ? 1 : 0, // Fade in on hover
                    }}
                  ></div> */}
                </div>
              </Link>
            </div>

            <div
              className="col-6 d-none d-lg-flex justify-content-start align-items-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Search Input */}
              <form
                onSubmit={handleSearch}
                className="d-flex align-items-center w-100"
                style={{ position: "relative" }}
              >
                <button
                  type="submit"
                  className="btn"
                  style={{
                    position: "absolute",
                    left: "10px",
                    background: "none",
                    border: "none",
                  }}
                >
                  <i className="fas fa-search"></i>
                </button>
                <input
                  type="text"
                  ref={searchInputRef}
                  className="form-control"
                  placeholder="Search 2000+ Bleeps"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    width: "100%",
                    paddingBlock: "15px",
                    paddingInline: "50px",
                    borderRadius: "100px",
                    backgroundColor: "white",
                    // border: "1px solid #ff4800",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "#2222224d", // Darker background for a key-like effect
                    color: "white",
                    border: "1px solid #55555556", // Slight border to define the key
                    borderRadius: "6px", // More rounded edges
                    padding: "6px 10px", // Proper padding to mimic key proportions
                    fontSize: "0.9em",
                    fontFamily: "monospace", // Mimic keyboard key text
                    fontWeight: "bold",
                    textAlign: "center",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.5)", // Adds depth
                    right: 20,
                    display: "inline-block",
                    opacity: 0.8,
                    cursor: "pointer",
                    pointerEvents: "none",
                  }}
                >
                  [/]
                </div>
              </form>
            </div>

            <div className="col d-flex justify-content-end align-items-center">
              <div className="d-none d-md-flex">
                <div className="d-md-flex justify-content-end">
                  <Link
                    to="/about"
                    className="text-decoration-none d-flex align-items-center"
                    style={{ paddingInline: "20px" }}
                  >
                    <a className="nav-btn">About</a>
                  </Link>
                </div>

                <div className="d-md-flex justify-content-end">
                  <Link
                    to="/faqs"
                    className="text-decoration-none d-flex align-items-center"
                    style={{ marginRight: "20px" }}
                  >
                    <a className="nav-btn">FAQs</a>
                  </Link>
                </div>

                <div className="d-none d-md-flex justify-content-end">
                  <Link
                    to="/contact"
                    className="text-decoration-none d-flex align-items-center"
                    style={{ marginRight: "20px" }}
                  >
                    <a className="nav-btn">Contact Us</a>
                  </Link>
                </div>
              </div>

              {!userId ? (
                <div className="d-md-flex justify-content-end">
                  <Link
                    to="/signin"
                    className="text-decoration-none d-flex align-items-center"
                  >
                    <a className="account-btn">
                      Sign-in <i className="fas fa-sign-in"></i>
                    </a>
                  </Link>
                </div>
              ) : (
                <div className="d-md-flex justify-content-end">
                  <div
                    class="dropdown-left"
                    style={{
                      background:
                        "linear-gradient(0deg, rgba(255, 175, 46, 1) 0%, rgba(255, 81, 0, 1) 100%)",
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      data-bs-toggle="dropdown"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        backgroundColor: "#ffffff",
                        cursor: "pointer",
                        // add space between padding and outline
                      }}
                    >
                      <img
                        src={avatar}
                        alt="Avatar"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </div>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item"
                          href="/mybleeps"
                        >
                          My Bleeps
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="/myprofile"
                        >
                          My Profile
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={signOut}
                        >
                          Sign-out <i className="fas fa-sign-out"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {(userData[1] === "superadmin" || userData[1] === "admin") && (
        <div
          className="bleep-subnavbar"
          style={{
            overflowX: "auto",
            width: "100%",
          }}
        >
          <span class="badge text-bg-danger">
            <i className="fas fa-info-circle"></i> Admin tools
          </span>
          <div>
            <a href="/dashboard">
              {" "}
              <i className="fas fa-th-large"></i> Dashboard
            </a>
          </div>
          <div>
            <a href="/genuineproducts">
              {" "}
              <i class="fa-solid fa-box"></i> Genuine Products
            </a>
          </div>
          <div>
            <a href="/organizations">
              {" "}
              <i class="fa-solid fa-building"></i> Organizations
            </a>
          </div>
          <div>
            <a href="/accounts">
              {" "}
              <i className="fas fa-users"></i> Accounts
            </a>
          </div>
          <div>
            <a href="/analytics">
              <i className="fas fa-chart-line"></i> Analytics
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
