import React, { useEffect, useState } from "react";

import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Route, Routes, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import Bleeps from "./pages/Bleeps";
import Dashboard from "./pages/Dashboard";
import MyBleeps from "./pages/MyBleeps";
import MyProfile from "./pages/MyProfile";
import Post from "./pages/Post";
import SignIn from "./pages/SignIn";
import Accounts from "./pages/Accounts";
import Organizations from "./pages/Organizations";
import Analytics from "./pages/Analytics";
import Downloads from "./pages/Downloads";
import Verify from "./pages/Verify";
import TermsOfService from "./pages/TermsOfService";
import About from "./pages/About";
import Contact from "./pages/Contact";
import FAQs from "./pages/FAQs";
import GenuineProducts from "./pages/GenuineProducts";

const App = () => {
  const hostname = window.location.hostname;
  let domain;
  if (hostname.endsWith(".ke")) {
    domain = "ke";
  } else if (hostname.endsWith(".ug")) {
    domain = "ug";
  } else {
    domain = "ug";
  }

  const [userData, setUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getUserData = () => {
    const newData = [
      Cookies.get("userId"),
      Cookies.get("userType"),
      Cookies.get("userName"),
      Cookies.get("userEmail") || Cookies.get("userPhone"),
      Cookies.get("userPassword"),
      Cookies.get("userOrg"),
      Cookies.get("userPermissions"),
      Cookies.get("userAnonymous"),
    ];
    setUserData(newData);
  };

  useEffect(() => {
    getUserData();

    // Check if the "userId" cookie is expired
    const userIdCookie = Cookies.get("userId");
    const isUserIdExpired =
      userIdCookie && new Date(userIdCookie.expires) < new Date();

    if (isUserIdExpired) {
      window.location.reload(); // Refresh the page if the "userId" cookie is expired
    }
  }, []);

  const refreshUserData = () => {
    getUserData();
  };

  // Get the search term from the URL
  const currentLocation = useLocation();
  useEffect(() => {
    if (currentLocation.pathname != "/bleeps") {
      setSearchTerm("");
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const searchTerm = urlParams.get("q");
      if (searchTerm) {
        setSearchTerm(searchTerm);
      }
    }
  }, [currentLocation.pathname]);

  const signOut = () => {
    Cookies.remove("userId");
    Cookies.remove("userType");
    Cookies.remove("userName");
    Cookies.remove("userEmail");
    Cookies.remove("userPhone");
    Cookies.remove("userPassword");
    Cookies.remove("userOrg");
    Cookies.remove("userPermissions");
    window.location.href = "/";
  };

  const location = useLocation();
  const hideNavbarPaths = ["/signin"];

  return (
    <>
      {!hideNavbarPaths.includes(location.pathname) && (
        <Navbar
          domain={domain}
          userData={userData}
          signOut={signOut}
          search={searchTerm}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <Home
              domain={domain}
              userData={userData}
              refreshUserData={refreshUserData}
              signOut={signOut}
            />
          }
        />
        <Route
          path="/bleeps"
          element={
            <Bleeps
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <Dashboard
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/mybleeps"
          element={
            <MyBleeps
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/myprofile"
          element={
            <MyProfile
              domain={domain}
              userData={userData}
              refreshUserData={refreshUserData}
            />
          }
        />
        <Route
          path="/post/:id"
          element={
            <Post
              domain={domain}
              refreshUserData={refreshUserData}
              userData={userData}
            />
          }
        />
        <Route
          path="/about"
          element={
            <About
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/signin"
          element={
            <SignIn
              domain={domain}
              userData={userData}
              handleSignIn={getUserData}
            />
          }
        />
        <Route
          path="/genuineproducts"
          element={
            <GenuineProducts
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/organizations"
          element={
            <Organizations
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/accounts"
          element={
            <Accounts
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/analytics"
          element={
            <Analytics
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/downloads"
          element={
            <Downloads
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/verify"
          element={
            <Verify
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/termsofservice"
          element={
            <TermsOfService
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/about"
          element={
            <About
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact
              domain={domain}
              userData={userData}
            />
          }
        />
        <Route
          path="/faqs"
          element={
            <FAQs
              domain={domain}
              userData={userData}
            />
          }
        />
      </Routes>
      <Footer
        domain={domain}
        userData={userData}
        signOut={signOut}
      />
    </>
  );
};

export default App;
