import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateGenuineProduct from "../components/CreateGenuineProduct";
import ConfirmModal from "../components/ConfirmModal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const GenuineProducts = ({ domain, userData }) => {
  const navigate = useNavigate();
  const [genuineProducts, setGenuineProducts] = useState([]);
  const [productId, setProductId] = useState("");

  const [showCreateGenuineProductModal, setShowCreateGenuineProductModal] =
    useState(false);

  const handleCloseCreateGenuineProductModal = () => {
    setShowCreateGenuineProductModal(false);
  };
  const handleShowCreateGenuineProductModal = () => {
    setShowCreateGenuineProductModal(true);
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const fetchGenuineProducts = () => {
    fetch("/api/genuineProducts")
      .then((response) => response.json())
      .then((data) => setGenuineProducts(data))
      .catch((error) => console.error("Error fetching products:", error));
  };

  useEffect(() => {
    fetchGenuineProducts();
  }, []);

  const handleFormSubmit = () => {
    fetchGenuineProducts();
  };

  const handleDeleteProduct = (id) => {
    handleShowConfirmModal();
    setProductId(id);
  };

  const handleProceed = () => {
    fetch(`/api/genuineProducts/${productId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          fetchGenuineProducts();
        } else {
          console.error("Error deleting product:", response);
        }
      })
      .catch((error) => console.error("Error deleting product:", error));
    handleCloseConfirmModal();
  };

  function convertDateTimeFormat(dateTimeString) {
    const dateTime = new Date(dateTimeString).toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    return dateTime;
  }

  Fancybox.bind("[data-fancybox]", {});

  return (
    <>
      <Helmet>
        <title>Genuine Products | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <div
          className="row d-flex align-items-center"
          style={{
            position: "relative",
          }}
        >
          <div
            className="col"
            style={{ color: "white", padding: "20px" }}
          >
            <div
              className="col d-flex align-items-center"
              style={{ color: "white", flexDirection: "row" }}
            >
              <Link
                to="/"
                className="text-decoration-none text-white"
              >
                <span
                  style={{
                    backgroundColor: "#00000089",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginRight: "10px",
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </span>
              </Link>

              <div>
                <b
                  style={{
                    fontSize: "30px",
                    textShadow: "#1c1c1c 1px 2px 0px",
                  }}
                >
                  Genuine Products
                </b>
              </div>
            </div>

            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              Manage genuine products on BLEEP!
            </p>

            <button
              type="button"
              className="btn btn-light btn-lg bleep-btn"
              onClick={handleShowCreateGenuineProductModal}
            >
              <span style={{ marginRight: "10px" }}>
                <i className="fa-solid fa-plus-circle"></i>
              </span>
              <b>Add</b>
            </button>
          </div>
        </div>
      </div>

      <div
        className="container p-4"
        style={{ overflowY: "auto", position: "relative" }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "separate",
            borderSpacing: "0 15px",
            backgroundColor: "transparent",
            color: "#FFF",
          }}
        >
          <thead>
            <tr>
              {[
                "#",
                "REF",
                "Images",
                "Brand",
                "Category",
                "Description",
                "Address",
                "Price",
                "Contact",
                "Date",
                "Action",
              ].map((header, index) => (
                <th
                  key={index}
                  style={{
                    padding: "15px",
                    textAlign: "left",
                    backgroundColor: "#2E1D17",
                    borderTopLeftRadius: index === 0 ? "20px" : "0",
                    borderTopRightRadius: index === 10 ? "20px" : "0",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {genuineProducts.map((genuineProduct) => (
              <tr
                style={{
                  backgroundColor: "#3e1b0e",
                  borderRadius: "20px",
                }}
              >
                <td
                  style={{
                    padding: "15px",
                    borderRadius: "20px 0 0 20px",
                  }}
                >
                  {genuineProducts.indexOf(genuineProduct) + 1}
                </td>
                <td style={{ padding: "15px" }}>
                  <b
                    style={{
                      marginTop: "8px",
                      color: "#FFF",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    #{genuineProduct._id.slice(-6)}
                  </b>
                </td>
                <td style={{ padding: "15px" }}>
                  <div
                    style={{
                      width: "150px",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    {genuineProduct.imageUrls &&
                      genuineProduct.imageUrls.map((url, index) => (
                        <img
                          data-fancybox={`product-${genuineProduct._id}`}
                          key={index}
                          src={url}
                          alt={`Product ${index + 1}`}
                          style={{
                            width: "100px", // Adjust size as needed
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "5px",
                            flexShrink: 0, // Prevents images from shrinking in flex container
                          }}
                        />
                      ))}
                  </div>
                </td>
                <td style={{ padding: "15px" }}>{genuineProduct.brand}</td>
                <td style={{ padding: "15px" }}>{genuineProduct.category}</td>
                <td style={{ padding: "15px" }}>
                  {genuineProduct.description}
                </td>
                <td style={{ padding: "15px" }}>{genuineProduct.address}</td>
                <td style={{ padding: "15px" }}>{genuineProduct.price} UGX</td>
                <td style={{ padding: "15px" }}>
                  {genuineProduct.contact.phone && (
                    <>
                      <i
                        className="fa fa-phone"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {genuineProduct.contact.phone}
                      <br />
                    </>
                  )}

                  {genuineProduct.contact.whatsapp && (
                    <>
                      <i
                        className="fab fa-whatsapp"
                        style={{ marginRight: "5px", color: "green" }}
                      ></i>
                      {genuineProduct.contact.whatsapp}
                      <br />
                    </>
                  )}

                  {genuineProduct.contact.email && (
                    <>
                      <i
                        className="fa fa-envelope"
                        style={{ marginRight: "5px", color: "#d44638" }}
                      ></i>
                      {genuineProduct.contact.email}
                      <br />
                    </>
                  )}

                  {genuineProduct.contact.website && (
                    <>
                      <i
                        className="fa fa-globe"
                        style={{ marginRight: "5px", color: "#007bff" }}
                      ></i>
                      <a
                        href={genuineProduct.contact.website}
                        target="_blank"
                      >
                        {genuineProduct.contact.website}
                      </a>
                      <br />
                    </>
                  )}
                </td>
                <td style={{ padding: "15px" }}>
                  {convertDateTimeFormat(genuineProduct.dateTime)}
                </td>
                <td
                  style={{
                    padding: "15px",
                    borderRadius: "0 20px 20px 0",
                  }}
                >
                  {genuineProduct.genuineProduct != "Greenbridge" &&
                    genuineProduct.genuineProduct != "ACN" && (
                      <a href="#">
                        <i
                          onClick={() =>
                            handleDeleteProduct(genuineProduct._id)
                          }
                          className="fas fa-trash-alt text-danger"
                        ></i>
                      </a>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CreateGenuineProduct
        domain={domain}
        show={showCreateGenuineProductModal}
        handleClose={handleCloseCreateGenuineProductModal}
        handleFormSubmit={handleFormSubmit}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={handleCloseConfirmModal}
        handleProceed={handleProceed}
        confirmMessage="CAUTION: Are you sure you want to delete this product? This cannot be
        undone!"
      />
    </>
  );
};

export default GenuineProducts;
