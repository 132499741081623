import React, { useEffect, useState, useRef } from "react";
import Post from "../components/Post";
import RemarksForm from "../components/RemarksForm";
import CRMFilterModal from "../components/CRMFilterModal";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { Helmet } from "react-helmet-async";

const Dashboard = ({ domain, userData }) => {
  const [userDataState, setUserDataState] = useState(userData);
  const [userOrganization, setUserOrganization] = useState("");
  useEffect(() => {
    setUserDataState(userData);

    // console.log("User data:", userData[1]);
    // if (!userData.length || userData[1] === "user") {
    //   // window.location.href = "/signin";
    //   console.log("Redirecting to signin...");
    // }
  }, [userData]);

  const tableContainerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const scrollToStart = () => {
    tableContainerRef.current.scrollTo({
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollToEnd = () => {
    const container = tableContainerRef.current;
    container.scrollTo({
      left: container.scrollWidth - container.clientWidth,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (!tableContainerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = tableContainerRef.current;
    setShowLeftButton(scrollLeft > 0);
    setShowRightButton(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (!tableContainer) return;

    const checkScroll = () => {
      requestAnimationFrame(handleScroll);
    };

    tableContainer.addEventListener("scroll", handleScroll);

    // Delay initial check to ensure table has rendered fully
    setTimeout(checkScroll, 100);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [tableContainerRef.current]);

  const [currentPost, setCurrentPost] = useState(null);
  const [showPriorityFormModal, setShowPriorityFormModal] = useState(false);
  const [currentPriority, setCurrentPriority] = useState(null);
  const handleClosePriorityFormModal = () => {
    setShowPriorityFormModal(false);
  };

  const statuses = [
    { value: "Under review", label: "Under review", color: "#FFA500" },
    { value: "Escalated", label: "Escalated", color: "#FF0000" },
    {
      value: "Awaiting feedback",
      label: "Awaiting feedback",
      color: "#FFD700",
    },
    { value: "Resolved", label: "Resolved", color: "#008000" },
  ];

  const priorities = [
    { value: "High", label: "High", color: "#FF0000" },
    { value: "Medium", label: "Medium", color: "#FFA500" },
    {
      value: "Low",
      label: "Low",
      color: "#008080",
    },
    { value: "Spam", label: "Spam", color: "#858585" },
  ];

  const handlePriorityChange = (newVal, post) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      priority: newVal.value,
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/api/tickets/${post}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchPosts(currentPage);
      })
      .catch((error) => console.error(error));
    handleClosePriorityFormModal();
  };

  const handleStatusChange = (newVal, post) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      status: [
        {
          status: newVal.value,
          user: userId,
        },
      ],
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/api/tickets/status/${post}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchPosts(currentPage);
      })
      .catch((error) => console.error(error));
  };

  const [showRemarksFormModal, setShowRemarksFormModal] = useState(false);
  const [currentRemarks, setCurrentRemarks] = useState(null);
  const handleCloseRemarksFormModal = () => {
    setShowRemarksFormModal(false);
  };

  const handleShowRemarksFormModal = (currentVal, post) => {
    setCurrentPost(post);
    setCurrentRemarks(currentVal);
    setShowRemarksFormModal(true);
  };

  const handleDocumentUpload = (event, ticketId) => {
    if (!userDataState) {
      window.location.href = "/signin";
      return;
    }

    const file = event.target.files[0]; // Get the selected file
    if (!file) return;

    const formData = new FormData();
    formData.append("document", file);
    formData.append("user", userDataState[0]); // Ensure backend gets user info

    fetch(`/api/tickets/${ticketId}/document`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("File uploaded:", result);
        fetchPosts(currentPage); // Refresh posts to reflect the uploaded file
      })
      .catch((error) => {
        console.error("File upload error:", error);
      });
  };

  const handleDeleteDocument = (post, document) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user: userDataState[0],
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/api/tickets/${post}/document/${document}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchPosts(currentPage);
      })
      .catch((error) => console.error(error));
  };

  const handleRemarksFormSubmit = (newVal) => {
    if (!userDataState) {
      window.location.href = "/signin";
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      comment: newVal,
      user: userDataState[0],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/api/tickets/${currentPost}/remarks`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchPosts(currentPage);
      })
      .catch((error) => console.error(error));
    handleCloseRemarksFormModal();
  };

  const [showCRMFilterModal, setShowCRMFilterModal] = useState(false);
  const handleCloseCRMFilterModal = () => {
    setShowCRMFilterModal(false);
  };

  const handleShowCRMFilterModal = () => {
    setShowCRMFilterModal(true);
  };

  const handleCRMFilterSubmit = (newVal) => {
    alert("Filter submitted: " + newVal);
  };

  const [
    userId,
    userType,
    userName,
    userEmail,
    userPassword,
    userOrg,
    userPermissions,
    userAnonymous,
  ] = userDataState;

  const [posts, setPosts] = useState([]);
  const [tags, setTags] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Find the user from the accounts array using the user ID from userDataState
    const user = accounts.find((account) => account._id === userDataState[0]); // Assuming 'userId' is the property in userDataState

    if (user) {
      setUserOrganization(user.organization);
    }
  }, [userDataState, accounts]); // Dependencies: userDataState and accounts

  const fetchTags = () => {
    fetch("/api/tickets/tags")
      .then((res) => res.json())
      .then((data) => {
        setTags(data);
      })
      .catch((error) => {
        console.error("Error fetching tags:", error);
      });
  };

  const fetchAccounts = () => {
    fetch("/api/users/admins")
      .then((res) => res.json())
      .then((data) => {
        setAccounts(data);
      })
      .catch((error) => {
        console.error("Error fetching accounts:", error);
      });
  };

  const autoAssignTickets = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`/api/tickets/autoassign?agent=${userId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        fetchPosts();
      })
      .catch((error) => console.error(error));
  };

  const handleTagChange = (selectedOptions, post) => {
    setPosts((prevRows) =>
      prevRows.map((row) =>
        row._id === post
          ? {
              ...row,
              ticket: {
                ...row.ticket,
                tags: selectedOptions.map((opt) => opt.value),
              },
            }
          : row
      )
    );

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      tags: selectedOptions.map((option) => option.value),
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/api/tickets/${post}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchPosts(currentPage);
      })
      .catch((error) => console.error(error));
    console.log(raw);
  };

  const handleAssigneeChange = (selectedOptions, post) => {
    const assignees = selectedOptions.map((option) => ({
      user: option.value, // Only include the user ID here
    }));

    setPosts((prevRows) =>
      prevRows.map((row) =>
        row._id === post
          ? {
              ...row,
              ticket: {
                ...row.ticket,
                assignees: assignees, // Update the assignees here
              },
            }
          : row
      )
    );

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      assignees: assignees, // Send assignees as an array of objects with user only
    });
    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/api/tickets/${post}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchPosts(currentPage);
      })
      .catch((error) => console.error(error));
    // console.log(raw);
  };

  function convertDateTimeFormat(dateTimeString) {
    const dateTime = new Date(dateTimeString).toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    return dateTime;
  }

  const [currentPage, setCurrentPage] = useState(1); // Start from page 1
  const [totalPages, setTotalPages] = useState(1); // Total pages from API
  const pageSize = 10; // Number of posts per page

  const [searchRef, setSearchRef] = useState("");

  const fetchPosts = (pageNumber = 1) => {
    let url = "";
    if (userType == "crmmanager" || userType == "superadmin") {
      if (searchRef == "") {
        url = `/api/tickets/postTickets?page=${pageNumber}&size=${pageSize}`;
      } else {
        url = `/api/tickets/postTickets?page=${pageNumber}&size=${pageSize}&postId=${searchRef}`;
      }
    } else {
      if (searchRef == "") {
        url = `/api/tickets/postTickets?page=${pageNumber}&size=${pageSize}&assignee=${userId}`;
      } else {
        url = `/api/tickets/postTickets?page=${pageNumber}&size=${pageSize}&postId=${searchRef}&assignee=${userId}`;
      }
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPosts(data.posts); // Replace instead of appending for pagination
        setTotalPages(data.totalPages); // Update total pages from API
        setCurrentPage(data.currentPage); // Update current page from API
        setHasMore(data.currentPage < data.totalPages); // Check if more pages are available
        setIsLoading(false);
        fetchTags();
        fetchAccounts();
        // console.log(userDataState[1]);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setIsLoading(false);
      });
  };

  const paginate = (pageNumber) => {
    setIsLoading(true); // Show loading while fetching
    fetchPosts(pageNumber); // Fetch data for selected page
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const visiblePages = 3; // Show first/last 3 pages
    const buffer = 2; // Adjacent pages to show

    // Handle edge case when totalPages is 1
    if (totalPages === 1) {
      buttons
        .push
        // <button
        //   key={1}
        //   onClick={() => paginate(1)}
        //   className={`btn ${currentPage === 1 ? "btn-dark" : "btn-light"}`}
        //   style={{ margin: "5px" }}
        // >
        //   1
        // </button>
        ();
      return buttons; // Return early since only one page exists
    }

    // First few pages
    for (let i = 1; i <= Math.min(visiblePages, totalPages); i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`btn ${i === currentPage ? "btn-dark" : "btn-light"}`}
          style={{ margin: "5px" }}
        >
          {i}
        </button>
      );
    }

    // Ellipsis if skipping pages
    if (currentPage > visiblePages + buffer) {
      buttons.push(
        <span
          key="dots1"
          style={{ margin: "5px", color: "white" }}
        >
          ...
        </span>
      );
    }

    // Middle pages around the current page
    const start = Math.max(visiblePages + 1, currentPage - buffer);
    const end = Math.min(totalPages - visiblePages, currentPage + buffer);
    for (let i = start; i <= end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`btn ${i === currentPage ? "btn-dark" : "btn-light"}`}
          style={{ margin: "5px" }}
        >
          {i}
        </button>
      );
    }

    // Ellipsis if skipping pages
    if (currentPage < totalPages - visiblePages - buffer) {
      buttons.push(
        <span
          key="dots2"
          style={{ margin: "5px", color: "white" }}
        >
          ...
        </span>
      );
    }

    // Last few pages
    for (
      let i = Math.max(totalPages - visiblePages + 1, visiblePages + 1);
      i <= totalPages;
      i++
    ) {
      buttons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`btn ${i === currentPage ? "btn-dark" : "btn-light"}`}
          style={{ margin: "5px" }}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  // Fetch posts on component mount
  useEffect(() => {
    fetchPosts();
    if (userType == "backoffice") {
      autoAssignTickets();
      // alert("Tickets auto-assigned to you!");
    }
  }, [userType]);

  return (
    <>
      <Helmet>
        <title>BLEEP CRM</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div
        style={{
          paddingTop: "40px",
          paddingBottom: "180px",
          height: "400px",
          background:
            "linear-gradient(90deg, rgba(255, 64, 0, 0.914) 0%, rgb(255, 153, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>

        <h1
          style={{
            textAlign: "center",
            color: "#FFF",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            fontWeight: "bold",
            position: "relative",
          }}
        >
          Welcome, {userName}
        </h1>
      </div>

      <div className="row m-0">
        <div
          className="col"
          style={{
            marginTop: "-180px",
            paddingTop: "10px",
            paddingInline: "0px",
            background:
              "linear-gradient(0deg, #150102 95%, rgba(255, 255, 255, 0)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              marginTop: "-80px",
              marginBottom: "40px",
              // backgroundColor: "#24120B",
              marginInline: "20px",
              padding: "20px",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                {/* <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="btnradio1"
                    autocomplete="off"
                    checked
                  ></input>
                  <label
                    class="btn btn-outline-warning"
                    for="btnradio1"
                  >
                    <i class="fas fa-bolt"></i> New
                  </label>

                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    autocomplete="off"
                  ></input>
                  <label
                    class="btn btn-outline-warning"
                    for="btnradio2"
                  >
                    <i class="fas fa-check-circle"></i> Completed
                  </label>
                </div> */}
                {/* search input */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search with REF"
                    style={{
                      backgroundColor: "#461704",
                      color: "#FFF",
                      border: "none",
                      width: "300px",
                      borderRadius: "10px",
                      padding: "5px",
                      paddingBlock: "15px",
                      paddingLeft: "10px",
                    }}
                    value={searchRef}
                    onChange={(e) => setSearchRef(e.target.value)}
                  />
                  <button
                    className="btn btn-dark"
                    style={{
                      borderRadius: "100px",
                    }}
                    onClick={() => fetchPosts()}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                {(userType == "crmmanager" || userType == "superadmin") && (
                  <button
                    className="btn btn-dark"
                    onClick={() => {
                      handleShowCRMFilterModal();
                    }}
                  >
                    <i className="fas fa-filter"></i> Filter
                  </button>
                )}

                {/* <button className="btn btn-dark">
                  <i className="fas fa-download"></i> Export
                </button> */}
              </div>
            </div>

            {/* Pagination */}
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              {renderPaginationButtons()}
            </div>

            {posts && posts.length ? (
              <div>
                <div style={{ position: "relative", width: "100%" }}>
                  {/* Left Scroll Button */}
                  {showLeftButton && (
                    <button
                      onClick={scrollToStart}
                      className="btn btn-outline-light"
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        position: "fixed",
                        left: "30px",
                        top: "60%",
                        transform: "translateY(-50%)",
                        zIndex: 1000, // Ensures buttons stay on top
                        pointerEvents: "auto",
                      }}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  )}

                  {/* Right Scroll Button */}
                  {showRightButton && (
                    <button
                      onClick={scrollToEnd}
                      className="btn btn-outline-light"
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        position: "fixed",
                        right: "30px",
                        top: "60%",
                        transform: "translateY(-50%)",
                        zIndex: 1000,
                        pointerEvents: "auto",
                      }}
                    >
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  )}
                </div>
                <div
                  id="tableContainer"
                  ref={tableContainerRef}
                  style={{
                    overflowX: "auto",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderSpacing: "0 15px",
                      backgroundColor: "transparent",
                      color: "#FFF",
                    }}
                  >
                    <thead>
                      <tr>
                        {[
                          "#",
                          "Bleep",
                          "Date posted",
                          "Status",
                          "Category",
                          "City",
                          "Amount",
                          "Description",
                          "Tags & Keywords",
                          "Assign",
                          "Priority",
                          "Internal Remarks",
                          "Documents",
                        ].map((header, index) => (
                          <th
                            key={index}
                            style={{
                              padding: "15px",
                              textAlign: "left",
                              backgroundColor: "#2E1D17",
                              borderTopLeftRadius: index === 0 ? "20px" : "0",
                              borderTopRightRadius: index === 12 ? "20px" : "0",
                            }}
                            // hide column if header is Assign and userData[5] != "Greenbridge" || "ACN"
                            hidden={
                              (index === 9 || index === 11) &&
                              userOrganization !== "Greenbridge" &&
                              userOrganization !== "ACN"
                            }
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map((row, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: "#3e1b0e",
                            borderRadius: "20px",
                          }}
                        >
                          <td
                            style={{
                              padding: "15px",
                              borderRadius: "20px 0 0 20px",
                            }}
                          >
                            {pageSize * (currentPage - 1) + index + 1}
                          </td>
                          <td>
                            {(!row.ticket?.assignees ||
                              row.ticket.assignees.length <= 1) && (
                              <span
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#c65210",
                                  color: "#ffffff",
                                  borderRadius: "20px",
                                  fontSize: "12px",
                                  display: "inline-flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <i className="fas fa-bolt"></i> NEW
                              </span>
                            )}

                            <a
                              href={`/post/${row._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                marginTop: "8px",
                                color: "#FFF",
                                textDecoration: "none",
                                fontWeight: "bold",
                              }}
                            >
                              #{row._id.slice(-6)}
                            </a>
                          </td>

                          <td style={{ padding: "15px" }}>
                            {convertDateTimeFormat(row.dateTime)}
                          </td>
                          <td style={{ padding: "15px" }}>
                            {userOrganization == "Greenbridge" ||
                            userOrganization == "ACN" ? (
                              <div
                                style={{ position: "relative", width: "250px" }}
                              >
                                <Select
                                  options={statuses}
                                  value={statuses.find(
                                    (option) =>
                                      row.ticket.status &&
                                      row.ticket.status.length > 0 &&
                                      option.value ===
                                        row.ticket.status[
                                          row.ticket.status.length - 1
                                        ]?.status
                                  )}
                                  onChange={(selectedOption) =>
                                    handleStatusChange(selectedOption, row._id)
                                  }
                                  placeholder="Select status"
                                  getOptionLabel={(e) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          backgroundColor: e.color,
                                          borderRadius: "50%",
                                          display: "inline-block",
                                        }}
                                      ></span>
                                      {e.label}
                                    </div>
                                  )}
                                  styles={{
                                    control: (styles) => ({
                                      ...styles,
                                      backgroundColor: "#461704",
                                      color: "#FFF",
                                      border: "1px solid #381818",
                                      borderRadius: "10px",
                                      padding: "5px",
                                    }),
                                    singleValue: (styles, { data }) => ({
                                      ...styles,
                                      color: data.color, // Color based on selected status
                                    }),
                                    indicatorSeparator: (styles) => ({
                                      ...styles,
                                      display: "none",
                                    }),
                                    dropdownIndicator: (styles) => ({
                                      ...styles,
                                      color: "#FFF",
                                    }),
                                    menu: (styles) => ({
                                      ...styles,
                                      backgroundColor: "#461704",
                                      color: "#FFF",
                                    }),
                                    option: (styles, { data, isFocused }) => ({
                                      ...styles,
                                      backgroundColor: isFocused
                                        ? "#381818"
                                        : "transparent",
                                      color: data.color, // Color based on status
                                      display: "flex",
                                      alignItems: "center",
                                    }),
                                    input: (styles) => ({
                                      ...styles,
                                      color: "#FFF", // White text when typing
                                    }),
                                    placeholder: (styles) => ({
                                      ...styles,
                                      color: "#CCC", // Light grey placeholder for visibility
                                    }),
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "180px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {statuses
                                  .filter(
                                    (status) => status.value === row.status
                                  )
                                  .map((status) => (
                                    <>
                                      <span
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          backgroundColor: status.color,
                                          borderRadius: "50%",
                                          display: "inline-block",
                                        }}
                                      ></span>
                                      {status.label}
                                    </>
                                  ))}
                              </div>
                            )}
                          </td>
                          <td style={{ padding: "15px" }}>{row.category}</td>
                          <td style={{ padding: "15px" }}>{row.city}</td>
                          <td style={{ padding: "15px" }}>{row.price}</td>
                          <td style={{ padding: "15px" }}>
                            <textarea
                              value={row.description}
                              style={{
                                width: "200px",
                                backgroundColor: "#461704",
                                color: "#FFF",
                                border: "1px solid #381818",
                                borderRadius: "10px",
                                padding: "10px",
                                fontSize: "12px",
                              }}
                              rows={4}
                              disabled
                            />
                          </td>

                          <td style={{ padding: "15px" }}>
                            {userOrganization == "Greenbridge" ||
                            userOrganization == "ACN" ? (
                              <div
                                style={{ position: "relative", width: "250px" }}
                              >
                                {/* Multiple tags input */}
                                <CreatableSelect
                                  isMulti
                                  options={
                                    (tags &&
                                      tags.map((tag) => ({
                                        value: tag,
                                        label: tag,
                                      }))) ||
                                    []
                                  }
                                  isClearable={false}
                                  value={
                                    row.ticket.tags?.map((tag) => ({
                                      value: tag,
                                      label: tag,
                                    })) || []
                                  }
                                  onChange={(selectedOptions) =>
                                    handleTagChange(selectedOptions, row._id)
                                  }
                                  placeholder="Add tags"
                                  styles={{
                                    control: (styles) => ({
                                      ...styles,
                                      backgroundColor: "#461704",
                                      color: "#FFF",
                                      border: "1px solid #381818",
                                      borderRadius: "10px",
                                      padding: "5px",
                                    }),
                                    singleValue: (styles) => ({
                                      ...styles,
                                      color: "#FFF",
                                    }),
                                    indicatorSeparator: (styles) => ({
                                      ...styles,
                                      display: "none",
                                    }),
                                    dropdownIndicator: (styles) => ({
                                      ...styles,
                                      color: "#FFF",
                                    }),
                                    menu: (styles) => ({
                                      ...styles,
                                      backgroundColor: "#461704",
                                      color: "#FFF",
                                    }),
                                    option: (styles, { data, isFocused }) => ({
                                      ...styles,
                                      backgroundColor: isFocused
                                        ? "#381818"
                                        : "transparent",
                                      color: "#FFF",
                                    }),
                                    input: (styles) => ({
                                      ...styles,
                                      color: "#FFF",
                                    }),
                                    placeholder: (styles) => ({
                                      ...styles,
                                      color: "#CCC",
                                    }),
                                    multiValue: (styles) => ({
                                      ...styles,
                                      backgroundColor: "#702a0e",
                                    }),
                                    multiValueLabel: (styles) => ({
                                      ...styles,
                                      color: "#FFF",
                                    }),
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {row.ticket.tags?.map((tag) => (
                                  <span
                                    key={tag}
                                    style={{
                                      padding: "5px 10px",
                                      backgroundColor: "#c65210",
                                      color: "#ffffff",
                                      borderRadius: "20px",
                                      fontSize: "12px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    {tag}
                                  </span>
                                ))}
                              </div>
                            )}
                          </td>

                          <td
                            style={{
                              padding: "15px",
                            }}
                            hidden={
                              userOrganization !== "Greenbridge" &&
                              userOrganization !== "ACN"
                            }
                          >
                            <div
                              style={{ position: "relative", width: "250px" }}
                            >
                              {/* Multiple assignees input */}
                              <Select
                                isMulti
                                options={
                                  (accounts &&
                                    Object.entries(
                                      accounts.reduce((groups, account) => {
                                        const { organizationType } = account;
                                        if (!groups[organizationType]) {
                                          groups[organizationType] = [];
                                        }
                                        groups[organizationType].push({
                                          value: account._id,
                                          label: `${account.email} (${account.organization})`,
                                        });
                                        return groups;
                                      }, {})
                                    ).map(([organizationType, group]) => ({
                                      label: organizationType, // This will be the label for the group
                                      options: group, // The actual accounts for that organization type
                                    }))) ||
                                  []
                                }
                                isClearable={false}
                                value={
                                  row.ticket.assignees?.map((assignee) => {
                                    // Find the user in the accounts array by matching the user ID
                                    const userAccount = accounts.find(
                                      (account) => account._id === assignee.user
                                    );

                                    // If the user is found, use their email; otherwise, display the user ID as fallback
                                    return {
                                      value: assignee.user, // The value is still the user ID
                                      label: userAccount
                                        ? userAccount.email
                                        : assignee.user, // The label is the email or fallback to user ID
                                    };
                                  }) || []
                                }
                                onChange={(selectedOptions) =>
                                  handleAssigneeChange(selectedOptions, row._id)
                                }
                                placeholder="Assign"
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    backgroundColor: "#461704",
                                    color: "#FFF",
                                    border: "1px solid #381818",
                                    borderRadius: "10px",
                                    padding: "5px",
                                  }),
                                  singleValue: (styles) => ({
                                    ...styles,
                                    color: "#FFF",
                                  }),
                                  indicatorSeparator: (styles) => ({
                                    ...styles,
                                    display: "none",
                                  }),
                                  dropdownIndicator: (styles) => ({
                                    ...styles,
                                    color: "#FFF",
                                  }),
                                  menu: (styles) => ({
                                    ...styles,
                                    backgroundColor: "#461704",
                                    color: "#FFF",
                                  }),
                                  option: (styles, { data, isFocused }) => ({
                                    ...styles,
                                    backgroundColor: isFocused
                                      ? "#381818"
                                      : "transparent",
                                    color: "#FFF",
                                  }),
                                  input: (styles) => ({
                                    ...styles,
                                    color: "#FFF",
                                  }),
                                  placeholder: (styles) => ({
                                    ...styles,
                                    color: "#CCC",
                                  }),
                                  multiValue: (styles) => ({
                                    ...styles,
                                    backgroundColor: "#702a0e",
                                  }),
                                  multiValueLabel: (styles) => ({
                                    ...styles,
                                    color: "#FFF",
                                  }),
                                }}
                                backspaceRemovesValue={false}
                                // components={{
                                //   MultiValueRemove: () => null, // Prevents removing items via backspace
                                // }}
                              />
                            </div>
                          </td>
                          <td
                            style={{
                              padding: "15px",
                            }}
                          >
                            {userOrganization == "Greenbridge" ||
                            userOrganization == "ACN" ? (
                              <div
                                style={{ position: "relative", width: "180px" }}
                              >
                                <Select
                                  options={priorities}
                                  value={priorities.find(
                                    (option) =>
                                      option.value === row.ticket.priority
                                  )}
                                  onChange={(selectedOption) =>
                                    handlePriorityChange(
                                      selectedOption,
                                      row._id
                                    )
                                  }
                                  placeholder="Select priority"
                                  getOptionLabel={(e) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          backgroundColor: e.color,
                                          borderRadius: "50%",
                                          display: "inline-block",
                                        }}
                                      ></span>
                                      {e.label}
                                    </div>
                                  )}
                                  styles={{
                                    control: (styles) => ({
                                      ...styles,
                                      backgroundColor: "#461704",
                                      color: "#FFF",
                                      border: "1px solid #381818",
                                      borderRadius: "10px",
                                      padding: "5px",
                                    }),
                                    singleValue: (styles, { data }) => ({
                                      ...styles,
                                      color: data.color, // Color based on selected status
                                    }),
                                    indicatorSeparator: (styles) => ({
                                      ...styles,
                                      display: "none",
                                    }),
                                    dropdownIndicator: (styles) => ({
                                      ...styles,
                                      color: "#FFF",
                                    }),
                                    menu: (styles) => ({
                                      ...styles,
                                      backgroundColor: "#461704",
                                      color: "#FFF",
                                    }),
                                    option: (styles, { data, isFocused }) => ({
                                      ...styles,
                                      backgroundColor: isFocused
                                        ? "#381818"
                                        : "transparent",
                                      color: data.color, // Color based on status
                                      display: "flex",
                                      alignItems: "center",
                                    }),
                                    input: (styles) => ({
                                      ...styles,
                                      color: "#FFF", // White text when typing
                                    }),
                                    placeholder: (styles) => ({
                                      ...styles,
                                      color: "#CCC", // Light grey placeholder for visibility
                                    }),
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {priorities
                                  .filter(
                                    (priority) =>
                                      priority.value === row.ticket.priority
                                  )
                                  .map((priority) => (
                                    <>
                                      <span
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          backgroundColor: priority.color,
                                          borderRadius: "50%",
                                          display: "inline-block",
                                        }}
                                      ></span>
                                      {priority.label}
                                    </>
                                  ))}
                              </div>
                            )}
                          </td>
                          <td
                            style={{
                              padding: "15px",
                            }}
                            hidden={
                              userOrganization !== "Greenbridge" &&
                              userOrganization !== "ACN"
                            }
                          >
                            <button
                              className="btn btn-dark"
                              onClick={() =>
                                handleShowRemarksFormModal(
                                  row.ticket?.remarks || [],
                                  row._id
                                )
                              }
                            >
                              <i class="fas fa-comment"></i>{" "}
                              {row.ticket?.remarks?.length || 0}
                            </button>
                          </td>

                          <td
                            style={{
                              padding: "15px",
                              borderRadius: "0 20px 20px 0",
                              position: "relative",
                            }}
                          >
                            {row.ticket &&
                              Object.keys(row.ticket).length !== 0 && (
                                <label
                                  className="btn btn-dark"
                                  style={{
                                    backgroundColor: "#ffffff",
                                    color: "#000",
                                    borderRadius: "20px",
                                    padding: "10px 15px",
                                    cursor: "pointer",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <i className="fas fa-paperclip"></i> Attach
                                  <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx"
                                    style={{
                                      display: "none",
                                    }}
                                    onChange={(e) => {
                                      handleDocumentUpload(e, row?._id);
                                    }}
                                  />
                                </label>
                              )}

                            <div
                              style={{
                                paddingTop: "10px",
                              }}
                            >
                              {row?.ticket.documents?.length > 0 ? (
                                <ul
                                  style={{
                                    padding: 0,
                                    listStyle: "none",
                                  }}
                                >
                                  {row.ticket.documents.map((doc, index) => {
                                    const fileUrl = doc.document[1];
                                    const fileName = doc.document[0]
                                      .split("/")
                                      .pop();

                                    // Limit filename length for better display
                                    const displayName =
                                      fileName.length > 20
                                        ? `${fileName.substring(0, 17)}...`
                                        : fileName;

                                    return (
                                      <li
                                        key={index}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "10px",
                                          marginBottom: "5px",
                                          padding: "5px 10px",
                                          borderRadius: "8px",
                                          backgroundColor: "#f9f9f9",
                                        }}
                                      >
                                        {/* File Link */}
                                        <a
                                          href={fileUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          title={fileName} // Tooltip shows full filename on hover
                                          style={{
                                            textDecoration: "none",
                                            color: "#007bff",
                                            flex: 1,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          <i className="fas fa-file-alt"></i>{" "}
                                          {displayName}
                                        </a>

                                        {/* Delete Button */}
                                        {doc.user === userId && (
                                          <button
                                            onClick={() =>
                                              handleDeleteDocument(
                                                row._id,
                                                doc._id
                                              )
                                            }
                                            style={{
                                              border: "none",
                                              background: "transparent",
                                              cursor: "pointer",
                                              color: "red",
                                              padding: "5px",
                                            }}
                                          >
                                            <i className="fas fa-trash-alt"></i>
                                          </button>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                <p
                                  style={{ color: "#777", fontStyle: "italic" }}
                                >
                                  No documents yet
                                </p>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                  color: "#FFF",
                }}
              >
                {isLoading ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                ) : (
                  <p>No posts found</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <RemarksForm
        domain={domain}
        show={showRemarksFormModal}
        remarks={currentRemarks}
        handleClose={handleCloseRemarksFormModal}
        handleFormSubmit={handleRemarksFormSubmit}
      />

      <CRMFilterModal
        show={showCRMFilterModal}
        handleClose={handleCloseCRMFilterModal}
        handleFormSubmit={handleCRMFilterSubmit}
      />
    </>
  );
};

export default Dashboard;
