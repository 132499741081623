import React, { useEffect, useState, useRef } from "react";
import Post from "../components/Post";
import ReportForm from "../components/ReportForm";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import lottie from "lottie-web";
// import Cookies from "js-cookie";
import lightninganimation from "../components/lottie/lightning.json";
import BleepSpinnerDark from "../components/lottie/BleepSpinnerDark.json";
import InfiniteScroll from "react-infinite-scroll-component";
import { motion } from "framer-motion";

import { Helmet } from "react-helmet-async";

const Home = ({ domain, userData, refreshUserData, signOut }) => {
  const [userDataState, setUserDataState] = useState(userData);
  const [showFloatingReportButton, setShowFloatingReportButton] =
    useState(false);

  // Search
  const [searchQuery, setSearchQuery] = useState("");
  const searchInputRef = useRef(null);
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery) {
      window.location.href = `/bleeps?q=${searchQuery}`;
    }
  };

  // check if url contains a query string ?redirect=report
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get("redirect");
    if (redirect === "report") {
      handleShowReportFormModal();
    }
  }, []);

  useEffect(() => {
    upadteDownloadTrackerCount();
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;

      // Show/hide button with animation
      setShowFloatingReportButton(scrollY > 200);
    };

    // Add scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const reportButtonVariants = {
    hidden: { opacity: 0, y: 20, transition: { duration: 0.3 } }, // Moves down while fading out
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } }, // Moves up while fading in
  };

  useEffect(() => {
    setUserDataState(userData);
  }, [userData]);

  const [
    userId,
    userType,
    userName,
    userEmail,
    userPassword,
    userOrg,
    userPermissions,
    userAnonymous,
  ] = userDataState;

  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const bleeploadinganimation = React.useRef(null);
  useEffect(() => {
    if (
      bleeploadinganimation.current &&
      !bleeploadinganimation.current.animationLoaded
    ) {
      bleeploadinganimation.current.animationLoaded = true; // Mark animation as loaded to prevent re-render
      lottie.loadAnimation({
        container: bleeploadinganimation.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        animationData: BleepSpinnerDark,
      });
    }
  }, [isLoading]);

  const lightninganimationcontainer = React.useRef(null);
  useEffect(() => {
    if (
      lightninganimationcontainer.current &&
      !lightninganimationcontainer.current.animationLoaded
    ) {
      lightninganimationcontainer.current.animationLoaded = true; // Mark animation as loaded to prevent re-render
      lottie.loadAnimation({
        container: lightninganimationcontainer.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        // speed: 0.2,
        animationData: lightninganimation,
      });
      lottie.setSpeed(0.6);
    }
  }, []);

  const fetchPosts = () => {
    let url = `/api/posts?page=${page}&size=4`;
    if (userId) {
      url += `?user=${userId}`;
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPosts((prevPosts) => [...prevPosts, ...data]);
        data.length > 0 ? setHasMore(true) : setHasMore(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setIsLoading(false);
      });
    setPage(page + 1);
  };

  // Fetch posts on component mount
  useEffect(() => {
    // setIsLoading(true);
    fetchPosts();
  }, [userId]);

  // Fetch posts after form submission
  const handleReportFormSubmit = () => {
    fetchPosts();
  };

  const [showReportFormModal, setShowReportFormModal] = useState(false);
  const handleCloseReportFormModal = () => {
    setShowReportFormModal(false);
    setReportFormInProgress(false);
  };
  const handleShowReportFormModal = () => {
    setShowReportFormModal(true);
    setReportFormInProgress(true);
  };

  const [reportFormInProgress, setReportFormInProgress] = useState(false);

  const handleShowSignIn = () => {
    window.location.href = "/signin?redirect=" + window.location.pathname;
  };

  const trackEvent = (action) => {
    fetch("/api/trackers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: userId,
        action: action,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Event tracked:", data);
      })
      .catch((error) => {
        console.error("Error tracking event:", error);
      });
  };

  const [downloadTrackerCount, setDownloadTrackerCount] = useState(0);

  const upadteDownloadTrackerCount = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("/api/trackers/count/download,apk", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDownloadTrackerCount(result.count);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <Helmet>
        <title>BLEEP - Suspect a counterfeit? Report Now!</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div
        style={{
          // paddingTop: "40px",
          paddingBottom: "180px",
          margin: 0,
          background:
            "linear-gradient(90deg, rgba(255, 64, 0, 0.914) 0%, rgb(255, 153, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <div
          className="d-flex align-items-center"
          style={{
            position: "relative",
            zIndex: 1000,
            margin: 0,
            padding: 0,
          }}
        >
          <div
            className="col col-md-6 col-lg-4"
            style={{
              color: "white",
              paddingInline: "30px",
              paddingTop: "20px",
            }}
          >
            {userEmail && (
              <div
                style={{
                  padding: "24px",
                  marginBottom: "32px",
                  borderRadius: "20px",
                  border: "2px solid rgba(255, 255, 255, 0.1)", // Softer border
                  background:
                    "linear-gradient(135deg, rgba(52, 24, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 100%)",
                  backdropFilter: "blur(10px)", // Glass effect
                  color: "#fff",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)", // Soft shadow
                }}
              >
                {/* User Type & Organization */}
                {userType !== "user" && (
                  <b style={{ fontSize: "14px", opacity: 0.8 }}>
                    {/* {userType} {userOrg ? ` - ${userOrg}` : ""} */}
                    {userType}
                  </b>
                )}

                {/* Welcome Message */}
                <h3
                  style={{
                    fontSize: "26px",
                    fontWeight: "600",
                    marginTop: "8px",
                    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  Welcome,
                  <br />
                  {userAnonymous === "true"
                    ? userId
                      ? `Bleeper#${userId.slice(-6)}`
                      : "Bleeper"
                    : userName}
                </h3>
              </div>
            )}

            {userId == null && (
              <p
                style={{
                  fontSize: "30px",
                  textShadow: "#1c1c1c 1px 2px 0px",
                }}
              >
                Welcome Bleeper!
              </p>
            )}
            <b
              style={{
                fontSize: "30px",
                lineHeight: "20px",
                textShadow: "#1c1c1c 1px 2px 0px",
              }}
            >
              Suspect a counterfeit?
            </b>
            <div
              style={{
                paddingTop: "20px",
              }}
              className="align-items-center"
            >
              <div
                style={{
                  marginBottom: "20px",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  paddingBlock: "10px",
                  // marginRight: "20px",
                  maxWidth: "100%",
                }}
              >
                {(userId == null || userType == "user") && (
                  <button
                    type="button"
                    className="btn btn-light btn-lg shimmer"
                    style={{
                      paddingBlock: 20,
                      paddingInline: 40,
                      borderRadius: 50,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <i className="fa-solid fa-bolt-lightning"></i>
                    </span>
                    <b>REPORT NOW</b>
                  </button>
                )}
              </div>

              {/* <a
                  onClick={() => {
                    trackEvent("Downloaded v1.2.2 Beta APK");
                  }}
                  href="/downloads/bleep v1.2.2 Beta.apk"
                  download={true}
                >
                  <div
                    className="badge rounded-pill text-bg-dark"
                    style={{
                      marginBottom: "20px",
                      padding: "10px",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <i className="fas fa-download"></i>
                    </span>
                    Download the Bleep App (Android)
                    {(userType === "superadmin" || userType === "admin") &&
                      " - " + downloadTrackerCount + " downloads"}
                  </div>
                </a> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "10px",
                  paddingBottom: "20px",
                  flexWrap: "wrap", // Allows wrapping on small screens
                }}
              >
                {/* Play Store Button */}
                <a
                  onClick={() => trackEvent("Clicked Play Store Link")}
                  href="https://play.google.com/store/apps/details?id=com.acha.BLEEP"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "inline-block" }}
                >
                  <img
                    src="/images/getitongoogleplaybadge.png"
                    alt="Get it on Google Play"
                    style={{
                      height: "50px",
                      maxWidth: "100%", // Ensures it scales properly
                      cursor: "pointer",
                      transition: "opacity 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.target.style.opacity = 0.8)}
                    onMouseLeave={(e) => (e.target.style.opacity = 1)}
                  />
                </a>

                {/* App Store Button with "Coming Soon" Label */}
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src="/images/appstore_btn.svg"
                    alt="Coming Soon on App Store"
                    style={{
                      height: "50px",
                      maxWidth: "100%", // Ensures it scales properly
                      filter: "grayscale(100%)",
                      opacity: 0.6,
                    }}
                  />

                  {/* "Coming Soon" Overlay */}
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%) rotate(-15deg)",
                      backgroundColor: "rgba(255, 0, 0, 0.8)",
                      color: "white",
                      fontWeight: "bold",
                      padding: "5px 50px",
                      borderRadius: "10px",
                      fontSize: "12px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      whiteSpace: "nowrap", // Prevents text from breaking
                    }}
                  >
                    <i className="fas fa-info-circle"></i> COMING SOON
                  </div>
                </div>
              </div>

              <div
                className="col d-flex d-md-none justify-content-start align-items-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: 20,
                }}
              >
                {/* Search Input */}
                <form
                  onSubmit={handleSearch}
                  className="d-flex align-items-center w-100"
                  style={{ position: "relative" }}
                >
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      position: "absolute",
                      left: "10px",
                      background: "none",
                      border: "none",
                    }}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                  <input
                    type="text"
                    ref={searchInputRef}
                    className="form-control"
                    placeholder="Search 2000+ Bleeps"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      width: "100%",
                      paddingBlock: "15px",
                      paddingInline: "50px",
                      borderRadius: "100px",
                      backgroundColor: "white",
                      // border: "1px solid #ff4800",
                    }}
                  />
                </form>
              </div>

              <div
                style={{
                  overflowX: "none",
                }}
              >
                <div
                  style={{
                    top: 0,
                    left: 0,
                    position: "absolute",
                    pointerEvents: "none",
                  }}
                >
                  <div
                    ref={lightninganimationcontainer}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* Sliding image carousel */}
          <div
            className="col-md-6 col-lg-8 d-none d-md-block d-xl-block"
            style={{
              margin: "0px",
              marginBottom: "-120px",
              position: "relative",
              borderRadius: "300px 0px 0px 0px",
              WebkitMaskImage:
                "linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 20%)",
              maskImage:
                "linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 20%)",
            }}
            align="right"
          >
            <Splide
              options={{
                perPage: 1,
                rewind: true,
                autoplay: true,
                interval: 4000,
                arrows: false,
                pagination: false,
              }}
              style={{
                boxShadow: "0px 0px 40px 0px rgba(0,0,0,0.5)",
                overflow: "hidden",
              }}
            >
              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "black",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/supermarket.jpg"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "140px",
                      right: "-30px",
                      backgroundImage:
                        "repeating-radial-gradient(circle at 0 0, transparent 0, #ff8100 10px), repeating-linear-gradient(#ff090055, #ff0900)",
                      color: "#fff",
                      padding: "10px 60px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transform: "rotate(45deg)",
                      transformOrigin: "top right",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    REPORT NOW!
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ BLEEP!</b>
                    <br />
                    <p>Next time you spot a counterfeit at a store!</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "black",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/meds.jpg"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "140px",
                      right: "-30px",
                      backgroundImage:
                        "repeating-radial-gradient(circle at 0 0, transparent 0, #ff8100 10px), repeating-linear-gradient(#ff090055, #ff0900)",
                      color: "#fff",
                      padding: "10px 60px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transform: "rotate(45deg)",
                      transformOrigin: "top right",
                      borderRadius: "5px",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    REPORT NOW!
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ BLEEP!</b>
                    <br />
                    <p>Whenever you find any suspicious pharmaceuticals!</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "black",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/agro.jpg"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "140px",
                      right: "-30px",
                      backgroundImage:
                        "repeating-radial-gradient(circle at 0 0, transparent 0, #ff8100 10px), repeating-linear-gradient(#ff090055, #ff0900)",
                      color: "#fff",
                      padding: "10px 60px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transform: "rotate(45deg)",
                      transformOrigin: "top right",
                      borderRadius: "5px",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    REPORT NOW!
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ BLEEP!</b>
                    <br />
                    <p>When you spot a substandard agricultural product!</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "black",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/wine.jpg"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "140px",
                      right: "-30px",
                      backgroundImage:
                        "repeating-radial-gradient(circle at 0 0, transparent 0, #ff8100 10px), repeating-linear-gradient(#ff090055, #ff0900)",
                      color: "#fff",
                      padding: "10px 60px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transform: "rotate(45deg)",
                      transformOrigin: "top right",
                      borderRadius: "5px",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    REPORT NOW!
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ BLEEP!</b>
                    <br />
                    <p>Next time you spot a counterfeit drink!</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "black",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/makeup.jpg"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "140px",
                      right: "-30px",
                      backgroundImage:
                        "repeating-radial-gradient(circle at 0 0, transparent 0, #ff8100 10px), repeating-linear-gradient(#ff090055, #ff0900)",
                      color: "#fff",
                      padding: "10px 60px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transform: "rotate(45deg)",
                      transformOrigin: "top right",
                      borderRadius: "5px",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    REPORT NOW!
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ BLEEP!</b>
                    <br />
                    <p>When you find any counterfeit cosmetics!</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "black",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/cars.jpg"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "140px",
                      right: "-30px",
                      backgroundImage:
                        "repeating-radial-gradient(circle at 0 0, transparent 0, #ff8100 10px), repeating-linear-gradient(#ff090055, #ff0900)",
                      color: "#fff",
                      padding: "10px 60px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transform: "rotate(45deg)",
                      transformOrigin: "top right",
                      borderRadius: "5px",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    REPORT NOW!
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ BLEEP!</b>
                    <br />
                    <p>When you encounter a substandard vehicle!</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "black",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/clothes.jpg"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "140px",
                      right: "-30px",
                      backgroundImage:
                        "repeating-radial-gradient(circle at 0 0, transparent 0, #ff8100 10px), repeating-linear-gradient(#ff090055, #ff0900)",
                      color: "#fff",
                      padding: "10px 60px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transform: "rotate(45deg)",
                      transformOrigin: "top right",
                      borderRadius: "5px",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    REPORT NOW!
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ BLEEP!</b>
                    <br />
                    <p>If you find any counterfeit clothes!</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "black",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/tech.jpg"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "140px",
                      right: "-30px",
                      backgroundImage:
                        "repeating-radial-gradient(circle at 0 0, transparent 0, #ff8100 10px), repeating-linear-gradient(#ff090055, #ff0900)",
                      color: "#fff",
                      padding: "10px 60px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transform: "rotate(45deg)",
                      transformOrigin: "top right",
                      borderRadius: "5px",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    REPORT NOW!
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ BLEEP!</b>
                    <br />
                    <p>If you encounter any fake gadgets/electronics!</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "black",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/makeadifference.jpg"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "140px",
                      right: "-30px",
                      backgroundImage:
                        "repeating-radial-gradient(circle at 0 0, transparent 0, #ff8100 10px), repeating-linear-gradient(#ff090055, #ff0900)",
                      color: "#fff",
                      padding: "10px 60px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      transform: "rotate(45deg)",
                      transformOrigin: "top right",
                      borderRadius: "5px",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                    onClick={handleShowReportFormModal}
                  >
                    REPORT NOW!
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ BLEEP!</b>
                    <br />
                    <p>
                      In a world tainted with counterfeit products, you can make
                      a <b>bold</b> difference TODAY!
                    </p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "#ff5500",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/slide1.png"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "400px",
                    }}
                  />
                  <b
                    style={{
                      position: "absolute",
                      top: "10%",
                      fontSize: "200px",
                      color: "#000000",
                      mixBlendMode: "overlay",
                      opacity: 0.5,
                      textWrap: "nowrap",
                    }}
                  >
                    REPORT
                  </b>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>⚡️ REPORT</b>
                    <br />
                    <p>Empowering consumers to report counterfeit products.</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "#2d6a0c",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/slide2.png"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "400px",
                    }}
                  />
                  <b
                    style={{
                      position: "absolute",
                      top: "10%",
                      fontSize: "200px",
                      color: "#000000",
                      mixBlendMode: "overlay",
                      opacity: 0.5,
                      textWrap: "nowrap",
                    }}
                  >
                    VERIFY
                  </b>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>✅ VERIFY</b>
                    <br />
                    <p>Creating Awareness about Counterfeits</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "#d11515",
                    overflow: "hidden",
                    height: "600px",
                  }}
                >
                  <img
                    src="/images/slide3.png"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "400px",
                    }}
                  />
                  <b
                    style={{
                      position: "absolute",
                      top: "20%",
                      fontSize: "120px",
                      color: "#fff",
                      mixBlendMode: "overlay",
                      opacity: 0.5,
                      textWrap: "nowrap",
                    }}
                  >
                    TAKE ACTION
                  </b>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      height: "200px",
                      padding: "15px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      background:
                        "linear-gradient(0deg, #190505ff 60%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    className="caption"
                  >
                    <b>🚨 TAKE ACTION</b>
                    <br />
                    <p>
                      Increase responsiveness & accountability by brands and
                      enforcement agencies.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </div>

      <div className="row m-0">
        <div
          className="col"
          style={{
            marginTop: "-180px",
            paddingInline: "0px",
            background:
              "linear-gradient(0deg, #150102 95%, rgba(255, 255, 255, 0)",
            zIndex: 1000,
          }}
        >
          <div style={{ overflow: "hidden" }}>
            <InfiniteScroll
              dataLength={posts.length}
              next={fetchPosts}
              hasMore={hasMore}
              loader={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    ref={bleeploadinganimation}
                    style={{
                      width: 200,
                      height: 200,
                    }}
                  ></div>
                </div>
              }
              endMessage={
                <p style={{ textAlign: "center", color: "white" }}>
                  Looks like you've reached the beginning!{" "}
                  <i className="fas fa-glass-cheers"></i>
                </p>
              }
              style={{ overflow: "unset" }}
            >
              <div
                className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-1 row-cols-1"
                style={{
                  margin: 0,
                  padding: 0,
                  paddingTop: "2px",
                }}
              >
                {posts &&
                  posts.map((post) => (
                    <Post
                      key={post._id}
                      post={post}
                      showSignIn={handleShowSignIn}
                    />
                  ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 10,
          right: 20,
          zIndex: 1000,
        }}
      >
        {/* Report Button */}
        <motion.div
          variants={reportButtonVariants}
          animate={showFloatingReportButton ? "visible" : "hidden"} // Animate instead of mount/unmount
          initial="hidden"
          style={{ paddingBottom: "20px" }}
        >
          {(userId == null || userType == "user") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleShowReportFormModal}
            >
              <div className="btn-floating-report">
                <i
                  className="fa-solid fa-bolt-lightning"
                  style={{ color: "white", fontSize: 30 }}
                ></i>
              </div>
              <div
                style={{
                  backgroundColor: "#292929",
                  paddingInline: 10,
                  paddingBlock: 5,
                  fontSize: 10,
                  whiteSpace: "nowrap",
                  color: "white",
                  borderRadius: 20,
                  marginTop: -10,
                  zIndex: 1000,
                }}
              >
                <span>Report</span>
              </div>
            </div>
          )}
        </motion.div>
        <div
          style={{
            paddingBottom: "20px",
          }}
        >
          {/* Cypheme Button */}
          <Link
            to="/verify"
            className="text-decoration-none"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="btn-floating-verify">
                <img
                  src="/images/verify.svg"
                  style={{ height: "50px", filter: "invert(1)" }}
                />
              </div>
              <div
                style={{
                  backgroundColor: "#292929",
                  paddingInline: 10,
                  paddingBlock: 5,
                  fontSize: 10,
                  whiteSpace: "nowrap",
                  color: "white",
                  borderRadius: 20,
                  marginTop: -10,
                  zIndex: 1000,
                }}
              >
                <span>Verify product</span>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <ReportForm
        domain={domain}
        show={showReportFormModal}
        handleClose={handleCloseReportFormModal}
        handleFormSubmit={handleReportFormSubmit}
      />
    </>
  );
};

export default Home;
