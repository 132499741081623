import React, { useState, useEffect, forwardRef } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import citiesData from "./misc/cities.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CRMFilterModal = ({ domain, show, handleClose, handleFormSubmit }) => {
  const statuses = [
    { value: "Under review", label: "Under review", color: "#FFA500" },
    { value: "Escalated", label: "Escalated", color: "#FF0000" },
    {
      value: "Awaiting feedback",
      label: "Awaiting feedback",
      color: "#FFD700",
    },
    { value: "Resolved", label: "Resolved", color: "#008000" },
  ];

  const priorities = [
    { value: "High", label: "High", color: "#FF0000" },
    { value: "Medium", label: "Medium", color: "#FFA500" },
    {
      value: "Low",
      label: "Low",
      color: "#008080",
    },
    { value: "Spam", label: "Spam", color: "#858585" },
  ];

  const categoryOptions = [
    { value: "Agriculture", label: "Agriculture" },
    { value: "Apparel", label: "Apparel" },
    { value: "Automobiles", label: "Automobiles" },
    { value: "Beverages", label: "Beverages" },
    { value: "Construction", label: "Construction" },
    { value: "Cosmetics", label: "Cosmetics" },
    { value: "Currencies", label: "Currencies" },
    { value: "Education", label: "Education" },
    { value: "Electronics", label: "Electronics" },
    { value: "Food", label: "Food" },
    { value: "Government", label: "Government" },
    { value: "Media", label: "Media" },
    { value: "Organizations", label: "Organizations" },
    { value: "Petroleum", label: "Petroleum" },
    { value: "Pharmaceuticals", label: "Pharmaceuticals" },
  ];

  const cityOptions = citiesData.Uganda.map((city) => ({
    value: city,
    label: city,
  }));

  const [tags, setTags] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const fetchTags = () => {
    fetch("/api/tickets/tags")
      .then((res) => res.json())
      .then((data) => {
        setTags(data);
      })
      .catch((error) => {
        console.error("Error fetching tags:", error);
      });
  };

  const fetchAccounts = () => {
    fetch("/api/users/admins")
      .then((res) => res.json())
      .then((data) => {
        setAccounts(data);
      })
      .catch((error) => {
        console.error("Error fetching accounts:", error);
      });
  };

  useEffect(() => {
    fetchTags();
    fetchAccounts();
  }, [show]);

  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [priority, setPriority] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption);
  };

  const handleCityChange = (selectedOption) => {
    setCity(selectedOption);
  };

  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  const handlePriorityChange = (selectedOption) => {
    setPriority(selectedOption);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const BleepDatePicker = forwardRef(({ value, onClick, className }, ref) => (
    <button
      className={className}
      onClick={onClick}
      ref={ref}
      style={{
        backgroundColor: "#461704",
        color: "#FFF",
        border: "1px solid #381818",
        borderRadius: "10px",
        padding: "5px",
        width: "100%",
      }}
    >
      {value || "Select Date"}
    </button>
  ));

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ backdropFilter: "blur(2px)" }}
    >
      <Modal.Header
        style={{ backgroundColor: "#FCF1E6" }}
        closeButton
      >
        <Modal.Title>
          <b>Filter</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ position: "relative", width: "100%" }}>
          <Select
            options={statuses}
            onChange={(selectedOption) => handleStatusChange(selectedOption)}
            placeholder="Status"
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <span
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: e.color,
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></span>
                {e.label}
              </div>
            )}
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: "#461704",
                color: "#FFF",
                border: "1px solid #381818",
                borderRadius: "10px",
                padding: "5px",
              }),
              singleValue: (styles, { data }) => ({
                ...styles,
                color: data.color, // Color based on selected status
              }),
              indicatorSeparator: (styles) => ({
                ...styles,
                display: "none",
              }),
              dropdownIndicator: (styles) => ({
                ...styles,
                color: "#FFF",
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: "#461704",
                color: "#FFF",
              }),
              option: (styles, { data, isFocused }) => ({
                ...styles,
                backgroundColor: isFocused ? "#381818" : "transparent",
                color: data.color, // Color based on status
                display: "flex",
                alignItems: "center",
              }),
              input: (styles) => ({
                ...styles,
                color: "#FFF", // White text when typing
              }),
              placeholder: (styles) => ({
                ...styles,
                color: "#CCC", // Light grey placeholder for visibility
              }),
            }}
          />
        </div>

        <div style={{ position: "relative", width: "100%", marginTop: "10px" }}>
          <Select
            options={categoryOptions}
            onChange={(selectedOption) => console.log(selectedOption)}
            placeholder="Select category"
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: "#461704", // Dark background
                color: "#FFF", // White text color
                border: "1px solid #381818", // Dark border
                borderRadius: "10px", // Rounded corners
                padding: "10px", // Inner padding
                width: "100%", // Full width
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#FFF", // Text color
              }),
              indicatorSeparator: (styles) => ({
                ...styles,
                display: "none", // Remove separator between dropdown icon and options
              }),
              dropdownIndicator: (styles) => ({
                ...styles,
                color: "#FFF", // Icon color
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: "#461704", // Background color of the dropdown
                color: "#FFF", // Text color of options
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isFocused
                  ? "#381818"
                  : isSelected
                  ? "#5f2f1e"
                  : "transparent", // Background color when option is focused or selected
                color: isFocused || isSelected ? "#FFF" : "#FFF", // Text color
                display: "flex",
                alignItems: "center",
              }),
              input: (styles) => ({
                ...styles,
                color: "#FFF", // White text when typing
              }),
              placeholder: (styles) => ({
                ...styles,
                color: "#CCC", // Light grey placeholder
              }),
            }}
          />
        </div>

        <div style={{ position: "relative", width: "100%", marginTop: "10px" }}>
          <Select
            options={cityOptions}
            onChange={handleCityChange}
            placeholder="Select City"
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: "#461704", // Dark background
                color: "#FFF", // White text color
                border: "1px solid #381818", // Dark border
                borderRadius: "10px", // Rounded corners
                padding: "10px", // Inner padding
                width: "100%", // Full width
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#FFF", // Text color
              }),
              indicatorSeparator: (styles) => ({
                ...styles,
                display: "none", // Remove separator between dropdown icon and options
              }),
              dropdownIndicator: (styles) => ({
                ...styles,
                color: "#FFF", // Icon color
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: "#461704", // Background color of the dropdown
                color: "#FFF", // Text color of options
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isFocused
                  ? "#381818"
                  : isSelected
                  ? "#5f2f1e"
                  : "transparent", // Background color when option is focused or selected
                color: isFocused || isSelected ? "#FFF" : "#FFF", // Text color
                display: "flex",
                alignItems: "center",
              }),
              input: (styles) => ({
                ...styles,
                color: "#FFF", // White text when typing
              }),
              placeholder: (styles) => ({
                ...styles,
                color: "#CCC", // Light grey placeholder
              }),
            }}
          />
        </div>

        <div style={{ position: "relative", width: "100%", marginTop: "10px" }}>
          <Select
            isMulti
            options={
              (tags &&
                tags.map((tag) => ({
                  value: tag,
                  label: tag,
                }))) ||
              []
            }
            isClearable={false}
            onChange={(selectedOptions) => console.log(selectedOptions)}
            placeholder="Tags"
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: "#461704",
                color: "#FFF",
                border: "1px solid #381818",
                borderRadius: "10px",
                padding: "5px",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#FFF",
              }),
              indicatorSeparator: (styles) => ({
                ...styles,
                display: "none",
              }),
              dropdownIndicator: (styles) => ({
                ...styles,
                color: "#FFF",
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: "#461704",
                color: "#FFF",
              }),
              option: (styles, { data, isFocused }) => ({
                ...styles,
                backgroundColor: isFocused ? "#381818" : "transparent",
                color: "#FFF",
              }),
              input: (styles) => ({
                ...styles,
                color: "#FFF",
              }),
              placeholder: (styles) => ({
                ...styles,
                color: "#CCC",
              }),
              multiValue: (styles) => ({
                ...styles,
                backgroundColor: "#702a0e",
              }),
              multiValueLabel: (styles) => ({
                ...styles,
                color: "#FFF",
              }),
            }}
          />
        </div>

        <div style={{ position: "relative", width: "100%", marginTop: "10px" }}>
          <Select
            options={priorities}
            onChange={(selectedOption) => console.log(selectedOption)}
            placeholder="Priority"
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <span
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: e.color,
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></span>
                {e.label}
              </div>
            )}
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: "#461704",
                color: "#FFF",
                border: "1px solid #381818",
                borderRadius: "10px",
                padding: "5px",
              }),
              singleValue: (styles, { data }) => ({
                ...styles,
                color: data.color, // Color based on selected status
              }),
              indicatorSeparator: (styles) => ({
                ...styles,
                display: "none",
              }),
              dropdownIndicator: (styles) => ({
                ...styles,
                color: "#FFF",
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: "#461704",
                color: "#FFF",
              }),
              option: (styles, { data, isFocused }) => ({
                ...styles,
                backgroundColor: isFocused ? "#381818" : "transparent",
                color: data.color, // Color based on status
                display: "flex",
                alignItems: "center",
              }),
              input: (styles) => ({
                ...styles,
                color: "#FFF", // White text when typing
              }),
              placeholder: (styles) => ({
                ...styles,
                color: "#CCC", // Light grey placeholder for visibility
              }),
            }}
          />
        </div>

        <div style={{ width: "100%", marginTop: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="startDate"
                style={{ color: "#000000", marginRight: "10px" }}
              >
                Start Date:
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                customInput={<BleepDatePicker className="custom-input" />}
                calendarClassName="custom-calendar"
                dayClassName={(date) => "custom-day"}
                dateFormat="yyyy-MM-dd"
              />
            </div>

            <div style={{ flex: 1 }}>
              <label
                htmlFor="endDate"
                style={{ color: "#000000", marginRight: "10px" }}
              >
                End Date:
              </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={<BleepDatePicker className="custom-input" />}
                calendarClassName="custom-calendar"
                dayClassName={(date) => "custom-day"}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: "#FCF1E6",
        }}
      >
        <button
          type="button"
          className="btn btn-dark"
          onClick={() => {}}
        >
          Apply filters <i className="fas fa-arrow-right"></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CRMFilterModal;
