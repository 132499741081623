import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Helmet } from "react-helmet-async";

const SignIn = ({ domain, handleSignIn }) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidAuth, setInvalidAuth] = React.useState(false);
  const [invalidPassword, setInvalidPassword] = React.useState(false);
  const [invalidPhone, setInvalidPhone] = React.useState(false);
  const [regularUser, setRegularUser] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [verificationStep, setVerificationStep] = useState(0);
  const [proceedBtnText, setProceedBtnText] = useState(
    "Proceed <i class='fas fa-arrow-right'></i>"
  );

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300); // Adjust delay if needed
  }, []);

  // OTP input
  const [otp, setOTP] = useState(Array(6).fill("")); // State to hold OTP values
  const inputs = useRef([]);
  const [medium, setMedium] = useState("email");

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (!loading) {
      if (verificationStep == 0) {
        setProceedBtnText("Proceed <i class='fas fa-arrow-right'></i>");
      } else {
        setProceedBtnText("Authenticate <i class='fas fa-arrow-right'></i>");
      }
    } else {
      setProceedBtnText("<i class='fas fa-circle-notch fa-spin'></i>");
    }
  }, [verificationStep, loading]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        proceed();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [username, password, otp]);

  const handleInputChange = (index, value) => {
    value = value.toUpperCase();
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
    if (value && index < 5) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && !otp[index]) {
      const newOTP = [...otp];
      newOTP[index - 1] = ""; // Clear the previous input value
      setOTP(newOTP);
      inputs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("Text").toUpperCase();
    // Split the pasted text into individual characters
    const pastedChars = pastedText.split("");
    // Update OTP with pasted characters, starting from the first input
    const newOTP = Array(6).fill("");
    pastedChars.forEach((char, index) => {
      if (index < 6) {
        newOTP[index] = char;
      }
    });
    setOTP(newOTP);
  };

  const authenticateEmailorPhone = () => {
    // check email validity using regex
    if (username) {
      if (loading) return;
      setLoading(true);

      // Function to determine if input is an email or phone number
      const isEmail = (input) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
      const isPhoneNumber = (input) => {
        // Remove spaces for validation
        const cleanedInput = input.replace(/\s+/g, "");

        // Validate phone number with optional +, followed by 10-15 digits
        const phoneRegex = /^\+?\d{10,15}$/;

        return phoneRegex.test(cleanedInput);
      };

      // Function to create raw JSON payload
      const createPayload = (username) => {
        let rawData;

        if (isEmail(username)) {
          rawData = JSON.stringify({ email: username });
          setMedium("email");
        } else if (isPhoneNumber(username)) {
          rawData = JSON.stringify({ phone: username });
          setMedium("phone");
        } else {
          rawData = JSON.stringify({ email: "" });
          setInvalidEmail(true);
        }

        return rawData;
      };

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = createPayload(username);

      if (raw === JSON.stringify({ email: "" })) {
        setLoading(false);
        return;
      }

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/api/users/sendverification", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setInvalidEmail(false);
          setInvalidPhone(false);
          setInvalidAuth(false);
          setInvalidPassword(false);
          setLoading(false);
          if (result.message == "Escalated user") {
            setRegularUser(false);
            setVerificationStep(verificationStep + 1);
          } else {
            setRegularUser(true);
          }

          if (result.message == "Verification SMS sent.") {
            setInvalidPhone(false);
            setVerificationStep(verificationStep + 1);
          }

          if (
            result.message == "Invalid phone number." ||
            result.message == "Invalid or unsupported carrier."
          ) {
            setInvalidPhone(true);
          }

          if (result.message == "Verification email sent.") {
            setInvalidEmail(false);
            setInvalidPhone(false);
            setVerificationStep(verificationStep + 1);
          }
        })
        .catch((error) => {
          setInvalidEmail(true);
          setLoading(false);
          console.error("error", error);
        });
    } else {
      setInvalidEmail(true);
    }
  };

  const authenticateUser = () => {
    if (loading) return;
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (regularUser) {
      var raw = JSON.stringify({
        emailOrPhone: username,
        verificationCode: otp.join(""),
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/api/users/authenticateverification", requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Authentication failed");
          }
          return response.json();
        })
        .then((data) => {
          setInvalidAuth(false);

          Cookies.set("userId", data.user._id, { expires: 7 });
          Cookies.set("userType", data.user.type, { expires: 7 });
          Cookies.set("userName", data.user.username, { expires: 7 });
          if (data.user.email)
            Cookies.set("userEmail", data.user.email, { expires: 7 });
          Cookies.set("userPhone", data.user.phone, { expires: 7 });
          Cookies.set("userAnonymous", data.user.anonymous, { expires: 7 });
          handleSignIn();

          // check if url contains a query string ?redirect=report
          const urlParams = new URLSearchParams(window.location.search);
          const redirect = urlParams.get("redirect");
          if (redirect) {
            if (redirect === "report") {
              navigate(`/?redirect=report`);
            } else {
              navigate(`${redirect}`);
            }
          } else {
            navigate(`/`);
          }
        })
        .catch((error) => {
          setInvalidAuth(true);
          setLoading(false);
        });
    } else {
      var raw = JSON.stringify({
        username: username,
        password: password,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/api/users/authenticateadmin", requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Authentication failed");
          }
          return response.json();
        })
        .then((data) => {
          setInvalidPassword(false);

          Cookies.set("userId", data.user._id, { expires: 1 });
          Cookies.set("userType", data.user.type, { expires: 1 });
          Cookies.set("userName", data.user.username, { expires: 1 });
          Cookies.set("userEmail", data.user.email, { expires: 1 });
          Cookies.set("userPassword", password, { expires: 1 });
          Cookies.set("userOrg", data.user.organization, { expires: 1 });
          Cookies.set("userPermissions", data.user.permissions, {
            expires: 1,
          });
          Cookies.set("userAnonymous", data.user.anonymous, {
            expires: 1,
          });
          handleSignIn();
          if (data.user.type == "user") {
            // check if url contains a query string ?redirect=report
            const urlParams = new URLSearchParams(window.location.search);
            const redirect = urlParams.get("redirect");
            if (redirect) {
              if (redirect === "report") {
                navigate(`/?redirect=report`);
              } else {
                navigate(`${redirect}`);
              }
            } else {
              navigate(`/`);
            }
          } else {
            navigate(`/dashboard`);
          }
        })
        .catch((error) => {
          setInvalidPassword(true);
          setLoading(false);
        });
    }
  };

  const proceed = () => {
    // Authenticate email
    if (verificationStep == 0) {
      authenticateEmailorPhone();
    } else if (verificationStep == 1) {
      authenticateUser();
    }
  };

  const back = () => {
    setVerificationStep(0);
  };

  return (
    <>
      <Helmet>
        <title>Sign-In | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>
      <div
        style={{
          paddingBlock: 100,
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "120vh",
          }}
        ></div>
        <div
          style={{
            position: "relative",
            paddingInline: "10px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            className="col-12 col-xl-6 row sign-in-card"
            style={{
              borderRadius: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="col d-none d-lg-none d-xl-flex align-items-center justify-content-center"
              style={{
                paddingBlock: "15px",
                backgroundColor: "#0e0e0e",
                boxShadow: "0px 0px 30px 0px #000",
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
              }}
            >
              <Splide
                options={{
                  perPage: 1,
                  rewind: true,
                  autoplay: true,
                  interval: 4000,
                  arrows: false,
                }}
              >
                <SplideSlide>
                  <div style={{ position: "relative" }}>
                    <img
                      src="/images/slide1.png"
                      style={{ width: "100%", borderRadius: "20px" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        textAlign: "center",
                        width: "100%",
                        padding: "10px",
                        paddingBottom: "20px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "#fff",
                        borderRadius: "20px",
                        lineHeight: "20px",
                      }}
                      className="caption"
                    >
                      <b>REPORT</b>
                      <br />
                      <p>
                        Empowering consumers to report counterfeit products.
                      </p>
                    </div>
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div style={{ position: "relative" }}>
                    <img
                      src="/images/slide2.png"
                      style={{ width: "100%", borderRadius: "20px" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        textAlign: "center",
                        width: "100%",
                        padding: "10px",
                        paddingBottom: "20px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
                        color: "#fff", // Adjust text color as needed
                        borderRadius: "20px",
                        lineHeight: "20px",
                      }}
                      className="caption"
                    >
                      <b>VERIFY</b>
                      <br />
                      <p>Creating Awareness about Counterfeits</p>
                    </div>
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div style={{ position: "relative" }}>
                    <img
                      src="/images/slide3.png"
                      style={{ width: "100%", borderRadius: "20px" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        textAlign: "center",
                        width: "100%",
                        padding: "10px",
                        paddingBottom: "20px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
                        color: "#fff", // Adjust text color as needed
                        borderRadius: "20px",
                        lineHeight: "20px",
                      }}
                      className="caption"
                    >
                      <b>TAKE ACTION</b>
                      <br />
                      <p>
                        Increase responsiveness & accountability by brands and
                        enforcement agencies.
                      </p>
                    </div>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
            <div
              className="col-12 col-lg-6 "
              style={{
                padding: "20px",
                backgroundColor: "#f7e2cc",
                boxShadow: "0px 0px 100px 0px #000",
                borderRadius: "20px",
              }}
            >
              <div
                className="col-12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/"
                  className="text-decoration-none text-dark"
                >
                  <img
                    src="/images/bleeplogo_primary.png"
                    style={{
                      width: "150px",
                      height: "auto",
                      marginBottom: "20px",
                    }}
                  />
                </Link>
                <div className="d-flex justify-content-end">
                  <Link
                    className="text-dark"
                    onClick={() => navigate(-1)}
                    style={{ marginTop: "-15px", marginRight: "0px" }}
                  >
                    <i
                      className="fas fa-close"
                      style={{ fontSize: 30 }}
                    ></i>
                  </Link>
                </div>
              </div>

              {verificationStep == 0 && (
                <>
                  <h3>
                    <b>Sign-in</b>
                  </h3>

                  <p>
                    Enter your email address / phone number below to receive a
                    verification code
                  </p>

                  {invalidEmail && (
                    <div
                      className="alert alert-danger"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-circle"></i> Valid email
                      / phone required
                    </div>
                  )}

                  {invalidPhone && (
                    <div
                      className="alert alert-danger"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-circle"></i> Unsupported
                      phone number. We currently only support Airtel numbers.
                    </div>
                  )}

                  <div className="form-floating mb-3">
                    <input
                      ref={inputRef}
                      type="text"
                      className="form-control"
                      placeholder="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <label htmlFor="floatingInput">Your Email or Phone*</label>
                  </div>
                  <p>
                    By proceeding, you are accepting our{" "}
                    <Link
                      className="text-danger"
                      to="/termsofservice"
                      target="_blank"
                    >
                      Terms of Service
                    </Link>
                    .
                  </p>
                </>
              )}

              {verificationStep == 1 && regularUser && (
                <div>
                  <h3>
                    <b>Verification Code Sent!</b>
                  </h3>
                  <p>
                    Please check your {medium} <b>({username})</b> and enter the
                    6-digit code below. Can't find it? Please check your spam
                    folder.
                  </p>
                  {invalidAuth && (
                    <div
                      className="alert alert-danger"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-circle"></i> Code entered
                      is incorrect
                    </div>
                  )}
                  <div className="inputs d-flex flex-row justify-content-center mt-2">
                    {Array.from({ length: 6 }, (_, index) => (
                      <input
                        ref={(input) => (inputs.current[index] = input)}
                        key={index}
                        className="m-2 text-center form-control rounded fw-bold text-danger"
                        type="text"
                        maxLength="1"
                        value={otp[index]}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={handlePaste}
                        placeholder="x"
                      />
                    ))}
                  </div>
                </div>
              )}

              {verificationStep == 1 && !regularUser && (
                <div>
                  <h3>
                    <b>Welcome!</b>
                  </h3>
                  <p>Please enter your password below to proceed.</p>

                  {invalidPassword && (
                    <div
                      className="alert alert-danger"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-circle"></i> Password
                      entered is incorrect
                    </div>
                  )}

                  <div className="form-floating">
                    <input
                      autoFocus
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="password">Password*</label>
                  </div>
                  <div
                    className="form-check mt-2"
                    align="left"
                  >
                    <label
                      className="form-check-label"
                      style={{
                        userSelect: "none",
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={handleTogglePassword}
                      />
                      Show password
                    </label>
                  </div>
                </div>
              )}
              <div
                className="col"
                style={{
                  display: "flex",
                  gap: "10px",
                  // inbetween
                  justifyContent: "space-between",
                }}
              >
                {verificationStep > 0 && (
                  <button
                    type="button"
                    className="sign-in-btn btn-dark btn-lg mt-4"
                    style={{
                      paddingBlock: "20px",
                    }}
                    onClick={back}
                  >
                    Back
                  </button>
                )}
                <button
                  type="button"
                  id="proceed"
                  name="proceed"
                  className="sign-in-btn btn-gradient btn-lg mt-4"
                  style={{
                    paddingBlock: "20px",
                    width: "100%",
                  }}
                  onClick={proceed}
                  dangerouslySetInnerHTML={{
                    __html: proceedBtnText,
                  }}
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBlock: "50px",
          gap: "10px",
          flexWrap: "wrap", // Allows wrapping on small screens
        }}
      >
        {/* Play Store Button */}
        <a
          href="https://play.google.com/store/apps/details?id=com.acha.BLEEP"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "inline-block" }}
        >
          <img
            src="/images/getitongoogleplaybadge.png"
            alt="Get it on Google Play"
            style={{
              height: "50px",
              maxWidth: "100%", // Ensures it scales properly
              cursor: "pointer",
              transition: "opacity 0.3s ease",
            }}
            onMouseEnter={(e) => (e.target.style.opacity = 0.8)}
            onMouseLeave={(e) => (e.target.style.opacity = 1)}
          />
        </a>

        {/* App Store Button with "Coming Soon" Label */}
        <div
          style={{
            position: "relative",
            display: "inline-block",
            overflow: "hidden",
          }}
        >
          <img
            src="/images/appstore_btn.svg"
            alt="Coming Soon on App Store"
            style={{
              height: "50px",
              maxWidth: "100%", // Ensures it scales properly
              filter: "grayscale(100%)",
              opacity: 0.6,
            }}
          />

          {/* "Coming Soon" Overlay */}
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) rotate(-15deg)",
              backgroundColor: "rgba(255, 0, 0, 0.8)",
              color: "white",
              fontWeight: "bold",
              padding: "5px 50px",
              borderRadius: "10px",
              fontSize: "12px",
              textTransform: "uppercase",
              textAlign: "center",
              whiteSpace: "nowrap", // Prevents text from breaking
            }}
          >
            <i className="fas fa-info-circle"></i> COMING SOON
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
